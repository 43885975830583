import React from 'react';
import Navbar from '../../components/Navbar';
import Sidebar from '../../components/Sidebar';
import Footer from '../../components/Footer';
import ScrollButton from '../../utilities/ScrollButton';

import brownBasicWire from '../../images/brown-basic-wire.svg';
import redBasicWire from '../../images/red-basic-wire.svg';
import bigCrosses from '../../images/big-crosses.svg';
import hands from '../../images/hands.svg';
import chalice from '../../images/chalice.svg';
import handsBrid from '../../images/hands-bird.svg';
import candle from '../../images/candle.svg';

import './Liturgia.scss';

const Liturgia = () => {
  return (
    <div className='animation-wrapper'>
      <main className='default-container animation-section'>
        <Navbar />
        <Sidebar />
        <article
          className='default-content-wrapper'
          id='msza-swieta-content-wrapper'
        >
          <h1 className='subpage-title-left'>
            Msza Święta
          </h1>
          <div className='msza-swieta-content'>
            <strong>Niedziela:</strong>
            <br />
            18:00 (sobota) 8:00, 10:00, 12:00 (ze
            szczególnym udziałem rodzin z
            dziećmi), 18:00
            <br />
            <br />
            <strong>Dni powszednie:</strong>{' '}
            <br />
            8:00, 18:00
            <br />
            <br />
            <strong>Odwiedziny chorych:</strong> I
            sobota miesiąca przed południem.
            <br />
            <strong>
              Liturgia Godzin (Jutrznia):
            </strong>{' '}
            od poniedziałku do piątku od godz.
            7.15- 7.30.
            <br />
            <strong>
              Katecheza dla dorosłych:
            </strong>{' '}
            raz w miesiącu w niedzielę po każdej
            Mszy św. (opiekun ks. proboszcz, ks.
            wikariusz).
            <br />
            <strong>
              Uroczystość odpustowa:
            </strong>{' '}
            IV Niedziela Wielkanocna o godz.
            12.00.
            <br />
            <strong>
              Parafialny Dzień Chorego z
              udzieleniem sakramentu namaszczenia
              chorych:
            </strong>{' '}
            II sobota czerwca o godz. 10.00.
            <br />
            <br />
            <strong>
              Dodatkowo w dni powszednie o godz.
              17:00:
            </strong>
            <br /> 2.02 - Ofiarowanie Pańskie
            <br />
            19.03 - Józefa Oblubieńca N.M.P.{' '}
            <br />
            25.03 - Zwiastowanie N.M.P., N.M.P.
            Matki Kościoła, Najświętszego Serca
            Pana Jezusa <br />
            24.06 - Uroczystość Narodzenia św.
            Jana Chrzciciela <br />
            29.06 - Św. Apostołów Piotra i Pawła{' '}
            <br />
            6.08 - Przemienienie Pańskie <br />
            8.09 - Narodzenie N.M.P. <br />
            14.09 - Podwyższenie Krzyża Świętego{' '}
            <br />
            2.11 - Wszystkich Wiernych Zmarłych{' '}
            <br />
            8.12 - Niepokalane Poczęcie N.M.P.
          </div>
        </article>
        <img
          className='msza-swieta-big-crosses'
          src={bigCrosses}
          alt='krzyze'
        />
        <img
          className='basic-wire brown'
          src={brownBasicWire}
          alt='fala'
        />
        <img
          className='basic-wire red'
          src={redBasicWire}
          alt='fala'
        />
        <ScrollButton />
      </main>
      <section className='default-container animation-section'>
        <Navbar />
        <Sidebar />
        <article
          className='default-content-wrapper'
          id='spowiedz-swieta-content-wrapper'
        >
          <h1 className='subpage-title-center'>
            Spowiedź Święta
          </h1>
          <div className='spowiedz-swieta-content'>
            <p>
              Okazja do Spowiedzi świętej{' '}
              <strong>
                30 minut przed każdą Mszą Świętą.
              </strong>
            </p>
            <p>
              W <b>I czwartek miesiąca</b> od
              godz. 17.00-18.00, w{' '}
              <b>I piątek miesiąca</b> od godz.
              17.00-18.00 oraz od godz.
              19.00-20.00 (z wyjątkiem wakacji), w{' '}
              <b>I sobotę miesiąca</b> od godz.
              17.00-18.00.
            </p>
            <p>
              <strong>
                Spowiedź wielkopostna/adwentowa
              </strong>{' '}
              od godz. 7:30-8:30 i 16:30-18:00
              oraz 18:30-20:00
            </p>
          </div>
        </article>
        <img
          className='spowiedz-swieta-hands'
          src={hands}
          alt='krzyze'
        />
        <img
          className='basic-wire brown'
          src={brownBasicWire}
          alt='fala'
        />
        <img
          className='basic-wire red'
          src={redBasicWire}
          alt='fala'
        />
        <ScrollButton />
      </section>
      <section className='default-container animation-section'>
        <Navbar />
        <Sidebar />
        <article
          className='default-content-wrapper'
          id='adoracja-content-wrapper'
        >
          <h1 className='subpage-title-left'>
            Adoracja
          </h1>
          <p className='adoracja-content'>
            <p>
              Adoracja Najświętszego Sakramentu{' '}
              <strong>
                w dni powszednie 30 minut przed
                Mszą św.
              </strong>
            </p>
            <p>
              Adoracja Najświętszego Sakramentu{' '}
              <strong>
                z prowadzoną medytacją w I piątek
                miesiąca od godz. 18.30-20.00.
              </strong>
            </p>

            <p>
              <strong>Wieczysta adoracja</strong>{' '}
              7 marca i 29 października od godz.
              8:30-18:00.
            </p>
          </p>
        </article>
        <img
          className='adoracja-chalice'
          src={chalice}
          alt='krzyze'
        />
        <img
          className='basic-wire brown'
          src={brownBasicWire}
          alt='fala'
        />
        <img
          className='basic-wire red'
          src={redBasicWire}
          alt='fala'
        />
        <ScrollButton />
      </section>
      <section className='default-container animation-section'>
        <Navbar />
        <Sidebar />
        <article
          className='default-content-wrapper'
          id='blogoslawienstwa-content-wrapper'
        >
          <h1 className='subpage-title-left'>
            Błogosławieństwa
          </h1>
          <div className='blogoslawienstwa-content'>
            <strong>
              Dzieci rozpoczynających nowy rok
              szkolny i przyborów szkolnych:
            </strong>{' '}
            pierwsza niedziela września po Mszy
            św. o godz. 12.00.
            <br />
            <strong>
              Kierowców i pojazdów mechanicznych:
            </strong>{' '}
            pierwsza niedziela lipca po każdej
            Mszy św.
            <br />
            <strong>Ziół i kwiatów:</strong> 15
            sierpnia po każdej Mszy św.
            <br />
            <strong>Różańców:</strong> 7
            października po Mszy św. o godz. 8.00
            i 18.00.
            <br />
            <strong>Wieńca adwentowego:</strong> I
            niedziela Adwentu, po Mszy św. o godz.
            12.00.
            <br />
            <strong>Opłatków:</strong> I niedziela
            Adwentu po każdej Mszy św.
            <br />
            <strong>Medalików:</strong> 8 grudnia
            po Mszy św. o godz. 8.00, 17.00,
            18.00.
            <br />
            <strong>
              Figurki Dzieciątka:
            </strong>{' '}
            III niedziela Adwentu po Mszy św. o
            godz. 12.00.
            <br />
            <strong>Wina:</strong> 27 grudnia po
            Mszy św. o godz. 8.00 i 18.00.
            <br />
            <strong>Kredy i kadzidła:</strong> 6
            stycznia po każdej Mszy św.
            <br />
            <strong>Wody:</strong> Święto Chrztu
            Pańskiego na każdej Mszy św.
            <br />
            <strong>Świec gromnicznych:</strong> 2
            lutego po Mszy św. o godz. 8.00 i
            18.00.
            <br />
            <strong>
              Świec i wiernych z prośbą o
              zachowanie od chorób gardła:
            </strong>{' '}
            3 lutego po Mszy św. o godz. 8.00 i
            18.00.
            <br />
            <strong>
              Pokarmów na stół wielkanocny:
            </strong>{' '}
            Wielka Sobota o godz. 10.00, 11.00,
            12.00, 13.00, 14.00, 15.00, 16.00.
            <br />
            <strong>Wianków:</strong> zakończenie
            oktawy Bożego Ciała.
            <br />
            <strong>
              Dzieci i młodzieży przed wakacjami:
            </strong>{' '}
            w dniu rozdania świadectw: po Mszy św.
            o godz. 18.00.
          </div>
        </article>
        <img
          className='blogoslawienstwa-hands-bird'
          src={handsBrid}
          alt='krzyze'
        />
        <img
          className='basic-wire brown'
          src={brownBasicWire}
          alt='fala'
        />
        <img
          className='basic-wire red'
          src={redBasicWire}
          alt='fala'
        />
        <ScrollButton />
      </section>
      <section className='default-container animation-section'>
        <Navbar />
        <Sidebar />
        <article
          className='default-content-wrapper'
          id='nabozenstwa-content-wrapper'
        >
          <h1 className='subpage-title-left'>
            Nabożeństwa stałe
          </h1>
          <div className='nabozenstwa-content'>
            <strong>Poniedziałek:</strong>{' '}
            modlitwa za wstawiennictwem św. o. Pio
            po Mszy św. o godz. 18.00.
            <br />
            <strong>Wtorek:</strong> modlitwa za
            wstawiennictwem bł. Marii Karłowskiej
            po Mszy św. o godz. 18.00.
            <br />
            <strong>Środa:</strong> Modlitwa
            różańcowa o godz. 17.30. Msza św. w
            intencjach Nowenny do Matki Bożej
            Nieustającej Pomocy o godz. 18.00.
            Nowenna po Mszy św. o godz. 18.00.
            <br />
            <strong>I środa:</strong> modlitwa za
            wstawiennictwem św. Józefa o dobrą
            śmierć po Mszy św. o godz. 18.00
            <br />
            <strong>I czwartek:</strong> modlitwa
            różańcowa o godz. 17.30. Nabożeństwo
            eucharystyczne do Chrystusa Dobrego
            Pasterza i modlitwa o powołania
            kapłańskie, misyjne i zakonne oraz
            zmiana tajemnic po Mszy św. o godz.
            18.00.
            <br />
            <strong>Piątek:</strong> Godzinki do
            Miłosierdzia Bożego o godz. 17.30.
            Koronka do Miłosierdzia Bożego po Mszy
            św. o godz. 18.00 (z wyjątkiem I
            piątku i okresu Wielkiego Postu, maja
            i czerwca).
            <br />
            <strong>
              I piątek miesiąca:
            </strong>{' '}
            nabożeństwo wynagradzające ku czci
            Najświętszego Serca Pana Jezusa po
            Mszy św. o godz. 8.00 i 18.00. <br />
            <strong>
              I sobota miesiąca:
            </strong>{' '}
            nabożeństwo ku czci Niepokalanego
            Serca Najświętszej Maryi Panny i
            katecheza po Mszy św. o godz. 8.00.
            <br />
            <strong>Niedziela:</strong> Godzinki o
            Niepokalanym Poczęciu Najświętszej
            Maryi Panny o godz. 7.30.
          </div>
        </article>
        <img
          className='nabozenstwa-candle'
          src={candle}
          alt='krzyze'
        />
        <img
          className='basic-wire brown'
          src={brownBasicWire}
          alt='fala'
        />
        <img
          className='basic-wire red'
          src={redBasicWire}
          alt='fala'
        />
        <ScrollButton />
      </section>
      <div className='animation-section'>
        <section className='default-container'>
          <Navbar />
          <Sidebar />
          <article
            className='default-content-wrapper'
            id='nabozenstwa-content-wrapper'
          >
            <h1 className='subpage-title-left'>
              Nabożeństwa okresowe
            </h1>
            <div className='nabozenstwa-content'>
              <strong>1 listopada:</strong> Msza
              św. na cmentarzu o godz. 12.00, na
              zakończenie procesja.
              <br />
              <strong>2 listopada:</strong> Msza
              św. na cmentarzu o godz. 15.30.
              Modlitwa w intencji zmarłych i
              procesja na cmentarzu od 2 do 8
              listopada o godz. 16.00.
              <br />
              <strong>
                Różaniec (październik):
              </strong>{' '}
              codziennie o godz. 17.30.
              <br />
              <strong>
                Roraty (w okresie adwentu):
              </strong>{' '}
              od poniedziałku do piątku o godz.
              18.00.
              <br />
              <strong>Nieszpory maryjne</strong> w
              niedziele adwentu o godz. 17.30.
              <br />
              <strong>Droga krzyżowa</strong>{' '}
              (piątki Wielkiego Postu) o godz.
              17.00 i 18.30.
              <br />
              <strong>
                Gorzkie żale z kazaniem pasyjnym
              </strong>{' '}
              (niedziele Wielkiego Postu) o godz.
              17.15.
              <br />
              <strong>Majowe:</strong> po Mszy św.
              o godz. 18.00. W niedziele o godz.
              20.00 w plenerze.
              <br />
              <strong>Czerwcowe:</strong> po Mszy
              św. o godz. 18.00.
              <br />
              <strong>
                Modlitwy za osoby uzależnione
              </strong>{' '}
              w poniedziałki sierpnia po Mszy św.
              o godz. 18.00.
              <br />
              <strong>
                Nowenna przed Świętem Miłosierdzia
                Bożego
              </strong>{' '}
              od Wielkiego Piątku po każdej Mszy
              św.
              <br />
              <strong>
                Nowenna przed odpustem parafialnym
              </strong>{' '}
              od piątku po II Niedzieli
              Wielkanocnej po każdej Mszy św.{' '}
              <br />
              <strong>
                Nowenna przed Uroczystością
                Zesłania Ducha Świętego
              </strong>{' '}
              od piątku VI Niedzieli Wielkanocnej
              po każdej Mszy św.
              <br />
              <strong>
                Nabożeństwo fatimskie
              </strong>{' '}
              od maja do października każdego 13
              dnia miesiąca o godz. 20.00.
              <br />
              <strong>
                Wypominki (modlitwa za zmarłych):
              </strong>{' '}
              od 2 do 8 listopada po Mszy św. o
              godz. 18.00.
            </div>
          </article>
          <img
            className='nabozenstwa-candle'
            src={candle}
            alt='krzyze'
          />
          <img
            className='basic-wire brown'
            src={brownBasicWire}
            alt='fala'
          />
          <img
            className='basic-wire red'
            src={redBasicWire}
            alt='fala'
          />
        </section>
        <Footer />
      </div>
    </div>
  );
};

export default Liturgia;
