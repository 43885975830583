import React from 'react';
import Navbar from '../../components/Navbar';
import Sidebar from '../../components/Sidebar';
import Footer from '../../components/Footer';
import ScrollButton from '../../utilities/ScrollButton';

import basicWire from '../../images/basic-wire.svg';
import d19781 from '../../images/dekrety/1978/1.jpg';
import d19782 from '../../images/dekrety/1978/2.jpg';
import d19783 from '../../images/dekrety/1978/3.jpg';
import d19784 from '../../images/dekrety/1978/4.jpg';
import d19791 from '../../images/dekrety/1979/1.jpg';
import d19792 from '../../images/dekrety/1979/2.jpg';
import d19801 from '../../images/dekrety/1980/1.jpg';
import d19802 from '../../images/dekrety/1980/2.jpg';
import d19803 from '../../images/dekrety/1980/3.jpg';
import d19804 from '../../images/dekrety/1980/4.jpg';
import d19805 from '../../images/dekrety/1980/5.jpg';
import d19806 from '../../images/dekrety/1980/6.jpg';
import d19807 from '../../images/dekrety/1980/7.jpg';
import d19871 from '../../images/dekrety/1987/1.jpg';
import d19901 from '../../images/dekrety/1990/1.jpg';
import d20011 from '../../images/dekrety/2001/1.jpg';
import d20012 from '../../images/dekrety/2001/2.jpg';

import './Parafia.scss';

const Parafia = () => {
  return (
    <div className='animation-wrapper'>
      <main className='default-container animation-section'>
        <Navbar />
        <Sidebar />
        <article className='default-content-wrapper-without-img'>
          <h1 className='subpage-title-center'>
            Informacje ogólne
          </h1>
          <p className='informacje-ogolne-content'>
            <strong>Liczba mieszkańców:</strong> 5
            910
            <br />
            <strong>Odpust:</strong> Niedziela
            Dobrego Pasterza (suma odpustowa o
            godz. 12:00)
            <br />
            <strong>Wieczysta Adoracja:</strong> 7
            marca, 29 października
            <br />
            <strong>
              Świątynia według projektu
            </strong>{' '}
            Jana Raniszewskiego z Gdańska.
            <br />
            <br />
            <strong>Ważne daty:</strong>
            <br />
            <b>1.10.1978 r.</b> - Dekret Jego
            Eminencji Stefana Kardynała
            Wyszyńskiego Arcybiskupa
            Gnieźnieńskiego i Warszawskiego,
            Metropolity, Prymasa Polski w sprawie
            utworzenia Ośrodka Duszpasterskiego i
            mianowanie księdza Włodzimierza
            Reformata, proboszcza w Cielu,
            rektorem kaplicy publicznej w Białych
            Błotach.
            <br />
            <b>1.03.1979 r.</b> - Dekret Jego
            Eminencji Stefana Kardynała
            Wyszyńskiego Prymasa Polski erygujący
            kaplicę publiczną pod wezwaniem
            Chrystusa Dobrego Pasterza, w
            dekanacie bydgoskim III.
            <br />
            <b>25.04.1979 r.</b> - Poświęcenie
            kaplicy dokonane z polecenia Jego
            Eminencji Stefana Kardynała
            Wyszyńskiego Prymasa Polski przez Jego
            Ekscelencję księdza Biskupa Jana
            Michalskiego, biskupa pomocniczego
            Archidiecezji Gnieźnieńskiej.
            <br />
            <b>1.10.1980 r.</b> - Dekret Jego
            Eminencji Stefana Kardynała
            Wyszyńskiego Prymasa Polski erygujący
            parafię.
            <br />
            <b>1.07.1986 r.</b> - Jego Eminencja
            Józef Kardynał Glemp Prymas Polski,
            mianował księdza Franciszka Sołtysiaka
            proboszczem parafii.
            <br />
            <b>21.05.1988 r.</b> - wizytacja
            kanoniczna, dokonana przez Jego
            Ekscelencję księdza Biskupa Jana
            Czerniaka, biskupa pomocniczego
            Archidiecezji Gnieźnieńskiej.
            <br />
            <b>1.08.1990 r.</b> - wmurowanie
            kamienia węgielnego, w mury budowanej
            świątyni, przez Jego Eminencję Józefa
            Kardynała Glempa Prymasa Polski.
            <br />
            <b>7.06.1993 r.</b> - wizytacja
            kanoniczna, dokonana przez Jego
            Ekscelencję księdza Biskupa Jana
            Nowaka, biskupa pomocniczego
            Archidiecezji Gnieźnieńskiej.
            <br />
            <b>7.10.1997 r.</b> - wizytacja
            kanoniczna, dokonana przez Jego
            Ekscelencję księdza Biskupa Stanisława
            Gądeckiego, biskupa pomocniczego
            Archidiecezji Gnieźnieńskiej.
            <br />
            <b>20.10.2001 r.</b> - poświęcenie
            kościoła, dokonane z polecenia Jego
            Ekscelencji księdza Arcybiskupa
            Henryka Muszyńskiego Metropolity
            Gnieźnieńskiego przez Jego Ekscelencję
            księdza Biskupa Bogdana Wojtusia,
            biskupa pomocniczego Archidiecezji
            Gnieźnieńskiej.
            <br />
            <b>2003 r.</b> - wizytacja kanoniczna
            dokonana przez Jego Ekscelencję
            księdza Biskupa Bogdana Wojtusia,
            biskupa pomocniczego Archidiecezji
            Gnieźnieńskiej.
            <br />
            <b>25.03.2004 r.</b> - parafia
            znalazła się w strukturach
            nowopowstałej Diecezji Bydgoskiej.
            <br />
            <b>9.03.2008 r.</b> - wizytacja
            kanoniczna dokonana przez Jego
            Ekscelencję księdza Biskupa Jana
            Tyrawę, Biskupa Diecezjalnego
            Bydgoskiego.
            <br />
            <b>1.09.2008 r.</b> - erygowanie
            dekanatu Białe Błota przez Jego
            Ekscelencję księdza Biskupa Jana
            Tyrawę, Biskupa Diecezjalnego w
            Bydgoszczy.
            <br />
            <b>25.06.2009 r.</b> - Biskup
            Diecezjalny Bydgoski, mianował księdza
            Zbigniewa Ciorkowskiego proboszczem
            parafii.
            <br />
            <b>30.06.2012 r.</b> - Biskup
            Diecezjalny Bydgoski, mianował księdza
            dra Adama Sierzchułę proboszczem
            parafii.
            <br />
            <b>2.06.2013 r.</b> - wizytacja
            kanoniczna dokonana przez Jego
            Ekscelencję księdza Biskupa Jana
            Tyrawę, Biskupa Diecezjalnego
            Bydgoskiego.
            <br />
            <b>26.06.2017 r.</b> - Biskup
            Diecezjalny Bydgoski, mianował księdza
            dra hab. Edwarda Wasilewskiego
            proboszczem parafii.
            <br />
            <b>26.02.2018r.</b> - wizytacja
            kanoniczna dokonana przez Jego
            Ekscelencję księdza Biskupa Jana
            Tyrawę, Biskupa Diecezjalnego
            Bydgoskiego.
            <br />
            <br />
            <strong>
              Księgi metrykalne:
            </strong>{' '}
            ochrzczonych od 1978 r., małżeństw od
            1978 r., zmarłych od 1978 r.
            <br />
            <br />
            <strong>
              Ulice należące do parafii:
            </strong>{' '}
            Alibaby, Alpejska, Arlekina, Altanowa,
            Ametystowa, Asfaltowa, Azaliowa,
            Bajeczna, Barwinkowa, Barycka,
            Bazaltowa, Bagatela, Bałkańska,
            Bartnicza, Berberysowa, Betonowa,
            Bezpieczna, Biała, Boruty, Bracka,
            Cisowa, Cała, Cedrowa, Centralna,
            Chełmska, Chlebowa, Chmielarska,
            Chmielna, Chudoby, Cukiernicza,
            Cukrowa, Cynowa, Cyprysowa, Czajcza,
            Czarnoleska, Czekoladowa, Czeremchy,
            Czwartaków, Czysta, Czachary, Czerska,
            Gontowa, Gwarna, Daleka, Daliowa,
            Drzewna, Duńska, Dworska, Epokowa,
            Ezopa, Feniksa, Forteczna, Goplany,
            Gronowa, Guliwera, Hebanowa, Herbowa,
            Hippiczna, Hodowlana, Iglasta, Izoldy,
            Jaracza, Judyma, Jantarowa, Jaśminowa,
            Jutrzenki, Kadetów, Kapliczna,
            Konwaliowa, Lechicka, Leśna,
            Literacka, Ludowa, Lutników, Miła,
            Mimozy, Młoda, Moczary, Modra, Mokra,
            Niedzielna, Nizinna, Ofiar Hitleryzmu,
            Ogniskowa, Olchowa, Owcza, Ostróżki,
            Parkowa, Piesza, Popiela, Przemysłowa,
            Reduty, Różana, Rzepichy, Sielska,
            Sobótki, Szafirowa, Szubińska, Temidy,
            Trawiasta, Turkusowa, Tulipanowa,
            Ulana, Uroczysko, Wierzbowa, Zawiła,
            Zacisze, Zeusa, Żeńców, Żonkilowa.
          </p>
        </article>
        <img
          className='basic-wire'
          src={basicWire}
          alt='fala'
        />
        <ScrollButton />
      </main>
      <section className='default-container animation-section'>
        <Navbar />
        <Sidebar />
        <article className='default-content-wrapper-without-img'>
          <h1 className='subpage-title-center'>
            Dekanat
          </h1>
          <div className='flexbox-wrapper dekanat-flexbox'>
            <div className='dekanat-flexbox-element'>
              <div className='dekanat-parafia'>
                <strong>Ciele</strong>
                <br />
                Parafia pw. Matki Bożej Bolesnej
                <br />
                ul. Kościelna 1<br />
                Ciele 86-062
                <br />
                tel. 052-381-41-95
              </div>
            </div>
            <div className='dekanat-flexbox-element'>
              <div className='dekanat-parafia'>
                <strong>Dąbrówka Nowa</strong>
                <br />
                Parafia pw. Św. Jakuba Mniejszego
                Ap.
                <br />
                ul. Dąbrowiecka
                <br /> 86-014 Sicienko
                <br />
                tel. 52-381-55-82
              </div>
            </div>
            <div className='dekanat-flexbox-element'>
              <div className='dekanat-parafia'>
                <strong>Sicienko</strong>
                <br />
                Parafia pw. Św. Andrzeja Boboli
                <br />
                ul. Mrotecka 1<br />
                86-010 Sicienko
                <br />
                tel. 52-587-04-08
              </div>
            </div>
            <div className='dekanat-flexbox-element'>
              <div className='dekanat-parafia'>
                <strong>Wtelno</strong>
                <br />
                Parafia pw. Św. Michała Archanioła
                <br />
                ul. Kościelna 2<br />
                86-011 Wtelno
                <br />
                tel. 052-382-83-22
              </div>
            </div>
            <div className='dekanat-flexbox-element'>
              <a
                href='http://www.parafiakruszyn.bydgoszcz.pl/'
                className='dekanat-parafia'
              >
                <strong>Kruszyn</strong>
                <br />
                Parafia pw. Św. Kazimierza
                Królewicza
                <br />
                Kruszyn nr 90,
                <br />
                86-014 Sicienko
                <br />
                tel. 052-583-13-93
              </a>
            </div>
            <div className='dekanat-flexbox-element'>
              <a
                href='http://www.kazimierzkr.pl/'
                className='dekanat-parafia'
              >
                <strong>Łochowo</strong>
                <br />
                Parafia pw. Św. Kazimierza
                <br />
                ul. Dębowa 3,
                <br />
                86-065 Łochowo
                <br />
                tel. 052-381-93-92, 052-348-80-92
              </a>
            </div>
            <div className='dekanat-flexbox-element'>
              <a
                href='https://parafia-murowaniec.pl/'
                className='dekanat-parafia'
              >
                <strong>Murowaniec</strong>
                <br />
                Parafia pw. Św. Rafała
                Kalinowskiego
                <br />
                ul. Św. Rafała Kalinowskiego 2
                <br />
                86-005 Białe Błota
                <br />
                tel. 052-381-42-18
              </a>
            </div>
            <div className='dekanat-flexbox-element'>
              <a
                href='http://parafia-przyleki.pl/'
                className='dekanat-parafia'
              >
                <strong>Przyłeki</strong>
                <br />
                Parafia pw. Wniebowzięcia NMP
                <br />
                Zabytkowa 7<br />
                86-005 Przyłęki
                <br />
                tel. 660-111-333
              </a>
            </div>
          </div>
          <img
            className='basic-wire'
            src={basicWire}
            alt='fala'
          />
          <ScrollButton />
        </article>
      </section>
      <div className='animation-section'>
        <section className='default-container'>
          <Navbar />
          <Sidebar />
          <article className='default-content-wrapper-without-img'>
            <h1 className='subpage-title-center'>
              Dekrety
            </h1>
            <div class='flexbox-wrapper'>
              <div class='dekrety-flexbox-element'>
                <figure>
                  <img
                    src={d19781}
                    alt='Dekret 1 strona z 1978 roku'
                  />
                  <figcaption class='dekrety'>
                    1978 r.
                  </figcaption>
                </figure>
              </div>
              <div class='dekrety-flexbox-element'>
                <figure>
                  <img
                    src={d19782}
                    alt='Dekret 2 strona z 1978 roku'
                  />
                  <figcaption class='dekrety'>
                    1978 r.
                  </figcaption>
                </figure>
              </div>
              <div class='dekrety-flexbox-element'>
                <figure>
                  <img
                    src={d19783}
                    alt='Dekret 3 strona z 1978 roku'
                  />
                  <figcaption class='dekrety'>
                    1978 r.
                  </figcaption>
                </figure>
              </div>
              <div class='dekrety-flexbox-element'>
                <figure>
                  <img
                    src={d19784}
                    alt='Dekret 4 strona z 1978 roku'
                  />
                  <figcaption class='dekrety'>
                    1978 r.
                  </figcaption>
                </figure>
              </div>
              <div class='dekrety-flexbox-element'>
                <figure>
                  <img
                    src={d19791}
                    alt='Dekret 1 strona z 1979 roku'
                  />
                  <figcaption class='dekrety'>
                    1979 r.
                  </figcaption>
                </figure>
              </div>
              <div class='dekrety-flexbox-element'>
                <figure>
                  <img
                    src={d19792}
                    alt='Dekret 2 strona z 1979 roku'
                  />
                  <figcaption class='dekrety'>
                    1979 r.
                  </figcaption>
                </figure>
              </div>
              <div class='dekrety-flexbox-element'>
                <figure>
                  <img
                    src={d19801}
                    alt='Dekret 1 strona z 1980 roku'
                  />
                  <figcaption class='dekrety'>
                    1980 r.
                  </figcaption>
                </figure>
              </div>
              <div class='dekrety-flexbox-element'>
                <figure>
                  <img
                    src={d19802}
                    alt='Dekret 2 strona z 1980 roku'
                  />
                  <figcaption class='dekrety'>
                    1980 r.
                  </figcaption>
                </figure>
              </div>
              <div class='dekrety-flexbox-element'>
                <figure>
                  <img
                    src={d19803}
                    alt='Dekret 3 strona z 1980 roku'
                  />
                  <figcaption class='dekrety'>
                    1980 r.
                  </figcaption>
                </figure>
              </div>
              <div class='dekrety-flexbox-element'>
                <figure>
                  <img
                    src={d19804}
                    alt='Dekret 4 strona z 1980 roku'
                  />
                  <figcaption class='dekrety'>
                    1980 r.
                  </figcaption>
                </figure>
              </div>
              <div class='dekrety-flexbox-element'>
                <figure>
                  <img
                    src={d19805}
                    alt='Dekret 5 strona z 1980 roku'
                  />
                  <figcaption class='dekrety'>
                    1980 r.
                  </figcaption>
                </figure>
              </div>
              <div class='dekrety-flexbox-element'>
                <figure>
                  <img
                    src={d19806}
                    alt='Dekret 6 strona z 1980 roku'
                  />
                  <figcaption class='dekrety'>
                    1980 r.
                  </figcaption>
                </figure>
              </div>
              <div class='dekrety-flexbox-element'>
                <figure>
                  <img
                    src={d19807}
                    alt='Dekret 7 strona z 1980 roku'
                  />
                  <figcaption class='dekrety'>
                    1980 r.
                  </figcaption>
                </figure>
              </div>
              <div class='dekrety-flexbox-element'>
                <figure>
                  <img
                    src={d19871}
                    alt='Dekret 1 strona z 1987 roku'
                  />
                  <figcaption class='dekrety'>
                    2001 r.
                  </figcaption>
                </figure>
              </div>
              <div class='dekrety-flexbox-element'>
                <figure>
                  <img
                    src={d19901}
                    alt='Dekret 1 strona z 1990 roku'
                  />
                  <figcaption class='dekrety'>
                    2001 r.
                  </figcaption>
                </figure>
              </div>
              <div class='dekrety-flexbox-element'>
                <figure>
                  <img
                    src={d20011}
                    alt='Dekret 1 strona z 2001 roku'
                  />
                  <figcaption class='dekrety'>
                    2001 r.
                  </figcaption>
                </figure>
              </div>
              <div class='dekrety-flexbox-element'>
                <figure>
                  <img
                    src={d20012}
                    alt='Dekret 2 strona z 2001 roku'
                  />
                  <figcaption class='dekrety'>
                    2001 r.
                  </figcaption>
                </figure>
              </div>
            </div>
            <img
              className='basic-wire'
              src={basicWire}
              alt='fala'
            />
          </article>
        </section>
        <Footer />
      </div>
    </div>
  );
};

export default Parafia;
