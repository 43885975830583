import React from 'react';
import { HiChevronDoubleDown } from 'react-icons/hi';

import './ScrollButton.scss';

const ScrollButton = () => {
  return (
    <div className='scroll-button'>
      <div className='scroll-button-container'>
        <span className='scroll-button-text'>
          Przeglądaj &nbsp;
        </span>
        <HiChevronDoubleDown className='scroll-button-icon' />
      </div>
    </div>
  );
};

export default ScrollButton;
