import React from 'react';
import { Link } from 'react-router-dom';
import { AiFillFacebook } from 'react-icons/ai';
import { MdLocationPin } from 'react-icons/md';
import { GrMail } from 'react-icons/gr';

import logoWhite from '../../images/logo-light.svg';

import './Footer.scss';

const Footer = () => {
  return (
    <footer className='footer-main'>
      <div className='footer-main-container'>
        <div className='footer-main-wrappers'>
          <Link to='/' id='footer-main-logo'>
            <img src={logoWhite} alt='logo' />
          </Link>
        </div>
        <div className='footer-main-wrappers contact'>
          <div>
            <span className='footer-white'>
              881 926 303
            </span>
            <br />
            <span className='footer-dark'>
              kontakt@parafiabb.pl
            </span>
            <br />
            <span className='footer-white'>
              86-005 ul. Czerska 40
            </span>
            <br />
            <span className='footer-dark'>
              Białe Błota, Bydgoszcz
            </span>
          </div>
        </div>
        <div className='footer-main-wrappers items'>
          <Link
            className='item-title'
            to='/duszpasterze'
          >
            Duszpasterze
          </Link>
          <Link
            className='item-link'
            to='/duszpasterze'
          >
            Proboszcz
          </Link>
          <Link
            className='item-link'
            to='/duszpasterze'
          >
            Wikariusz
          </Link>
          <Link
            className='item-link'
            to='/duszpasterze'
          >
            Poprzedni Kapłanii
          </Link>
        </div>
        <div className='footer-main-wrappers'>
          <div className='footer-main-wrappers items'>
            <Link
              className='item-title'
              to='/parafia'
            >
              Parafia
            </Link>
            <Link
              className='item-link'
              to='/parafia'
            >
              Informacje Ogólne
            </Link>
            <Link
              className='item-link'
              to='/parafia'
            >
              Dekanat
            </Link>
            <Link
              className='item-link'
              to='/parafia'
            >
              Dekrety
            </Link>
          </div>
        </div>
        <div className='footer-main-media'>
          <div className='footer-main-media-wrappers socials'>
            <a
              href='https://www.facebook.com/parafiabb'
              target='_blank'
              rel='noreferrer'
            >
              <AiFillFacebook />
            </a>
            <a
              href='https://www.google.com/maps?ll=53.094967,17.913825&z=15&t=m&hl=pl&gl=PL&mapclient=embed&cid=3098536319807002130'
              target='_blank'
              rel='noreferrer'
            >
              <MdLocationPin />
            </a>
            <a href='mailto:kontakt@parafiabb.pl'>
              <GrMail />
            </a>
          </div>
          <div className='footer-main-media-wrappers rights'>
            {new Date().getFullYear()} &copy;
            Wszelkie prawa zastrzeżone.
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
