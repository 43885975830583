import React from 'react';

import Navbar from '../../components/Navbar';
import Sidebar from '../../components/Sidebar';
import Footer from '../../components/Footer';
import basicWire from '../../images/basic-wire.svg';

import './Intencje.scss';

const Intencje = () => {
  return (
    <div className='animation-wrapper'>
      <div className=' animation-section'>
        <main className='default-container'>
          <Navbar />
          <Sidebar />
          <section className='default-content-wrapper-without-img'>
            <h1 className='subpage-title-center'>
              Intencje Mszalne
            </h1>
            <article className='flexbox-wrapper intencje-flexbox'>
              <div className='intencje-flexbox-element'>
                <div className='intencja'>
                  <h3 className='intecja-title'>
                    Niedziela 15.09.2024 r.
                  </h3>
                  {/* <h4 className='intecja-undertitle'>
                    Uroczystość Najśw. Trójcy
                  </h4> */}
                  <br />
                  <b>8:00</b> Dziękczynna z okazji
                  40-ej rocz. Sakramentu
                  Małżeństwa Anny i Grzegorza z
                  prośbą o Boże błog. i opiekę MB
                  dla Jubilatów i rodziny
                  <br />
                  <b>8:00</b> + Jadwiga Kozaryn
                  (greg.14)
                  <br />
                  <b>10:00</b> + śp. rodzice
                  Władysław (z ok. ur.) i Jadwiga
                  Gwiździel oraz ich dzieci
                  <br />
                  <b>10:00</b> + śp. rodzice
                  Krystyna i Marian oraz zm. z
                  rodziny
                  <br />
                  <b>12:00</b> W 18 rocz. ur.
                  Wiktora i 24 rocz. ślubu
                  Agnieszki i Jarosława o Boże
                  błog. i opiekę MB oraz za + śp.
                  Zygmunt Kobylski, Beata Lapke,
                  zm. rodziców i teściów
                  <br />
                  <b>12:00</b> REZERWACJA
                  <br /> <b>12:00</b> REZERWACJA
                  <br />
                  <b>18:00</b> + Danuta, Kazimierz
                  i Mirosław Pachciarek oraz
                  Paulina i Mieczysław Radkiewicz
                </div>
              </div>
              <div className='intencje-flexbox-element'>
                <div className='intencja'>
                  <h3 className='intecja-title'>
                    Niedziela 22.09.2024 r.
                  </h3>
                  {/* <h4 className='intecja-undertitle'>
                    Uroczystość Najśw. Trójcy
                  </h4> */}
                  <br />
                  <b>8:00</b> Dziękczynna z okazji
                  12-ej rocz. Sakramentu
                  Małżeństwa Agnieszki i Piotra z
                  prośbą o Boże błog. i opiekę MB
                  dla małżonków i ich dzieci
                  <br />
                  <b>8:00</b> + Irena i Antoni
                  Moskal, zm. z rodziny i dusze w
                  czyśćcu cierpiące
                  <br />
                  <b>10:00</b> + Tekla, Jan,
                  Edmund, Teresa, Tadeusz, zm. z
                  rodziny i dusze w czyśćcu
                  cierpiące
                  <br />
                  <b>10:00</b> + Melania Jekel (7
                  rocz. śm.) i mąż Bogdan oraz zm.
                  rodziców i rodzeństwo – o dar
                  Nieba
                  <br />
                  <b>10:00</b> + Jadwiga Kozaryn
                  (greg.21)
                  <br />
                  <b>12:00</b> Dziękczynna z ok.
                  45 rocz. ślubu Renaty i Mariusza
                  z prośbą o Boże błog., wszelkie
                  łaski i opiekę Matki Bożej na
                  dalsze lata życia
                  <br />
                  <b>12:00</b> + Elżbieta
                  Kropińska (z ok. urodzin) – o
                  dar Nieba
                  <br /> <b>12:00</b> + Łukasz
                  Szatkowski (16 rocz. śm.)
                  <br />
                  <b>18:00</b> + Edmund Pawłowski
                  (8 rocz. śm.) oraz Robert
                  Wyrwicki (2 rocz. śm.) i zm. z
                  rodziny
                </div>
              </div>
              <div className='intencje-flexbox-element'>
                <div className='intencja'>
                  <h3 className='intecja-title'>
                    Poniedziałek 16.09.2024 r.
                  </h3>
                  {/* <h4 className='intecja-undertitle'>
                    Urocz. NMP Częstochowskiej
                  </h4> */}
                  <br />
                  <b>8:00</b> + Jadwiga Kozaryn
                  (greg.15)
                  <br />
                  <b>18:00</b> + Edyta Przybyła (z
                  okazji imienin) – int. od męża i
                  córek
                  <br />
                  <b>18:00</b> + Zofia Wysocka (1
                  rocz. śm.) – int. od męża i
                  dzieci z rodzinami
                  <br />
                  <b>18:00</b> + Bogusława(k) i
                  Józef Piekarz – int. od Heleny i
                  Witolda Piekarz
                </div>
              </div>
              <div className='intencje-flexbox-element'>
                <div className='intencja'>
                  <h3 className='intecja-title'>
                    Wtorek 17.09.2024 r.
                  </h3>
                  {/* <h4 className='intecja-undertitle'>
                    Święto Przemienienia Pańskiego
                  </h4> */}
                  <br />
                  <b>8:00</b> + Jadwiga Kozaryn
                  (greg.16)
                  <br />
                  <b>18:00</b> + Helena Słowińska
                  (w dniu urodzin)
                  <br />
                  <b>18:00</b> + Franciszek
                  Pawlikowski(w dniu imienin)-int.
                  od Jadwigi i Pawła Dunaj
                </div>
              </div>
              <div className='intencje-flexbox-element'>
                <div className='intencja'>
                  <h3 className='intecja-title'>
                    Środa 18.09.2024 r.
                  </h3>
                  <h4 className='intecja-undertitle'>
                    Św. Stanisław Kostka, patron
                    Polski
                  </h4>
                  <br />
                  <b>8:00</b> + Krystyna
                  <br />
                  <b>18:00</b> W intencji Nowenny
                  do MB Nieust. Pomocy:
                  <br />Z prośbą o potrzebne łaski
                  dla Magdy i Łukasza oraz o
                  wzrost wiary, nadziei i miłości
                  <br />+ Honorata, Stanisław i
                  Tadeusz Fladrowscy
                  <br />+ Jadwiga Kozaryn
                  (greg.17)
                </div>
              </div>
              <div className='intencje-flexbox-element'>
                <div className='intencja'>
                  <h3 className='intecja-title'>
                    Czwartek 19.09.2024 r.
                  </h3>
                  {/* <h4 className='intecja-undertitle'>
                    Zakończenie oktawy Bożego
                    Ciała
                  </h4> */}
                  <br />
                  <b>8:00</b> Wolna intencja
                  <br />
                  <b>18:00</b> + rodzice:
                  Stanisława(k) i Kazimierz
                  Leśniczuk oraz szwagier
                  Eugeniusz
                  <br />
                  <b>18:00</b> + Jadwiga Kozaryn
                  (greg.18)
                </div>
              </div>
              <div className='intencje-flexbox-element'>
                <div className='intencja'>
                  <h3 className='intecja-title'>
                    Piątek 20.09.2024 r.
                  </h3>
                  {/* <h4 className='intecja-undertitle'>
                    Urocz. Najśw. Serca Pana
                    Jezusa
                  </h4> */}
                  <br />
                  <b>8:00</b> Wolna intencja
                  <br />
                  <b>18:00</b> + Jadwiga Kozaryn
                  (greg.19)
                  <br />
                  <b>18:00</b> + Małgorzata
                  Fajge-Karmowska – int. od rodz.
                  Karmowskich z Królikowa
                </div>
              </div>
              <div className='intencje-flexbox-element'>
                <div className='intencja'>
                  <h3 className='intecja-title'>
                    Sobota 21.09.2024 r.
                  </h3>
                  <h4 className='intecja-undertitle'>
                    Św. Mateusz Ap. Ew.
                  </h4>
                  <br />
                  <b>8:00</b> + Jadwiga Kozaryn
                  (greg.20)
                  <br />
                  <b>16:00</b> Dziękczynna z
                  okazji 90-ej rocz. urodzin Pani
                  Marii Korytowskiej, z prośbą o
                  Boże błog., wszelkie łaski i
                  opiekę Matki Bożej
                  <br />
                  <b>18:00</b> + Zofia i Marian
                  Wąsikowscy i syn Kazimierz – o
                  dar Nieba
                </div>
              </div>
            </article>
            <img
              className='basic-wire'
              src={basicWire}
              alt='fala'
            />
          </section>
        </main>
        <Footer />
      </div>
    </div>
  );
};

export default Intencje;
