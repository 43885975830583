import React from 'react';
import Navbar from '../../components/Navbar';
import Sidebar from '../../components/Sidebar';
import Footer from '../../components/Footer';

import basicWire from '../../images/basic-wire.svg';

import './Galeria.scss';

const Galeria = () => {
  return (
    <div className='animation-wrapper'>
      <div className='animation-section'>
        <main className='default-container'>
          <Navbar />
          <Sidebar />
          <article
            className='default-content-wrapper-without-img'
            id='galeria-content-wrapper'
          >
            <h1 className='subpage-title-center'>
              Galeria
            </h1>
            <p className='galeria-index'>
              <a
                href='zdjecia/Boze-Cialo-2024'
                target='_blank'
                rel='noopener'
              >
                Uroczystość Najświętszego Ciała i
                Krwi Chrystusa -{' '}
                <b>30.05.2024 r.</b>
              </a>
              <br />
              <a
                href='zdjecia/Probosz-Poznaje-Biale-Blota-2023'
                target='_blank'
                rel='noopener'
              >
                Ksiądz proboszcz z grupą parafian
                poznaje Biale Blota -{' '}
                <b>27.07.2023 r.</b>
              </a>
              <br />
              <a
                href='zdjecia/Wprowadzenie-Arkadiusza-Muzolfa-2023'
                target='_blank'
                rel='noopener'
              >
                Uroczyste wprowadzenie ks.
                Arkadiusza Muzolfa w urząd
                proboszcza - <b>9.07.2023 r.</b>
              </a>
              <br />
              <a
                href='zdjecia/Poswiecenie-Krzyza-Cmentarz-2023'
                target='_blank'
                rel='noopener'
              >
                Poświęcenie krzyża na cmentarzu -{' '}
                <b>18.06.2023 r.</b>
              </a>
              <br />
              <a
                href='zdjecia/Pozegnianie-Edwarda-Wasilewskiego-2023'
                target='_blank'
                rel='noopener'
              >
                Pożegnanie księdza proboszcza
                Edwarda Wasilewskiego -{' '}
                <b>11.06.2023 r.</b>
              </a>
              <br />
              <a
                href='zdjecia/Boze-Cialo-2023'
                target='_blank'
                rel='noopener'
              >
                Uroczystość Najświętszego Ciała i
                Krwi Pańskiej -{' '}
                <b>8.06.2023 r.</b>
              </a>
              <br />
              <a
                href='zdjecia/Nabozenstwo-Majowe-w-Plenerze-3-2023'
                target='_blank'
                rel='noopener'
              >
                Nabożeństwo majowe w plenerze -{' '}
                <b>27.05.2023 r.</b>
              </a>
              <br />
              <a
                href='zdjecia/Nabozenstwo-Majowe-w-Plenerze-2-2023'
                target='_blank'
                rel='noopener'
              >
                Nabożeństwo majowe w plenerze -{' '}
                <b>20.05.2023 r.</b>
              </a>
              <br />
              <a
                href='zdjecia/Nabozenstwo-Majowe-w-Plenerze-2023'
                target='_blank'
                rel='noopener'
              >
                Nabożeństwo majowe w plenerze -{' '}
                <b>13.05.2023 r.</b>
              </a>
              <br />
              <a
                href='zdjecia/Odpust-2023'
                target='_blank'
                rel='noopener'
              >
                Uroczystość Odpustowa ku czci
                Chrystusa Dobrego Pasterza -{' '}
                <b>30.04.2023 r.</b>
              </a>
              <br />
              <a
                href='zdjecia/Niedziela-Biblijna-2023'
                target='_blank'
                rel='noopener'
              >
                Niedziela Biblijna -{' '}
                <b>23.04.2023 r.</b>
              </a>
              <br />
              <a
                href='zdjecia/Wigilia-Paschalna-2023'
                target='_blank'
                rel='noopener'
              >
                Wigilia Paschalna -{' '}
                <b>8.04.2023 r.</b>
              </a>
              <br />
              <a
                href='zdjecia/Blogoslawienstwo-Pokarmow-2023'
                target='_blank'
                rel='noopener'
              >
                Błogosławieństwo potraw -{' '}
                <b>8.04.2023 r.</b>
              </a>
              <br />
              <a
                href='zdjecia/Wielki-Piatek-2023'
                target='_blank'
                rel='noopener'
              >
                Wielki Piątek -{' '}
                <b>7.04.2023 r.</b>
              </a>
              <br />
              <a
                href='zdjecia/Wielki-Czwartek-2023'
                target='_blank'
                rel='noopener'
              >
                Wielki Czwartek -{' '}
                <b>6.04.2023 r.</b>
              </a>
              <br />
              <a
                href='zdjecia/Niedziela-Palmowa-2023'
                target='_blank'
                rel='noopener'
              >
                Niedziela Palmowa -{' '}
                <b>2.04.2023 r.</b>
              </a>
              <br />
              <a
                href='zdjecia/Droga-Krzyzowa-2023'
                target='_blank'
                rel='noopener'
              >
                Nabożeństwo Drogi krzyżowej
                ulicami parafii -{' '}
                <b>31.03.2023 r.</b>
              </a>
              <br />
              <a
                href='zdjecia/Carlo-Acutis'
                target='_blank'
                rel='noopener'
              >
                Wprowadzenie relikwii bł. Carlo
                Acutisa, patrona Internetu -{' '}
                <b>27.02.2023 r.</b>
              </a>
              <br />
              <a
                href='zdjecia/Sroda-Popielcowa-2023'
                target='_blank'
                rel='noopener'
              >
                Środa popielcowa -{' '}
                <b>22.02.2023 r.</b>
              </a>
              <br />
              <a
                href='zdjecia/Ofiarowanie-Panskie-2023'
                target='_blank'
                rel='noopener'
              >
                Ofiarowanie Pańskie -{' '}
                <b>2.02.2023 r.</b>
              </a>
              <br />
              <a
                href='zdjecia/Objawienie-Panskie-2023'
                target='_blank'
                rel='noopener'
              >
                Objawienie Pańskie -{' '}
                <b>6.01.2023 r.</b>
              </a>
              <br />
              <a
                href='zdjecia/Pasterka-2022'
                target='_blank'
                rel='noopener'
              >
                Pasterka - <b>24.12.2022 r.</b>
              </a>
              <br />
              <a
                href='zdjecia/Swiatlo-Betlejemskie-2022'
                target='_blank'
                rel='noopener'
              >
                Światełko Betlejemskie -{' '}
                <b>18.12.2022 r.</b>
              </a>
              <br />
              <a
                href='zdjecia/Wszystkich-Swietych-2022'
                target='_blank'
                rel='noopener'
              >
                Uroczystość Wszystkich Świętych -{' '}
                <b>1.11.2022 r.</b>
              </a>
              <br />
              <a
                href='zdjecia/Blogoslawienstwo-Rozancow-2022'
                target='_blank'
                rel='noopener'
              >
                Błogosławieństwo różańców -{' '}
                <b>2.10.2022 r.</b>
              </a>
              <br />
              <a
                href='zdjecia/Misjonarz-Krzysztof-Homilia-Dla-Dzieci-2022'
                target='_blank'
                rel='noopener'
              >
                Misjonarz ks. Krzysztof - homilia
                dla dzieci - <b>2.10.2022 r.</b>
              </a>
              <br />
              <a
                href='zdjecia/Blogoslawienstwo-Ziol-2022'
                target='_blank'
                rel='noopener'
              >
                Błogosławieństwo ziół i kwiatów -{' '}
                <b>15.08.2022 r.</b>
              </a>
              <br />
              <a
                href='zdjecia/Swiety-Krzysztof-2022'
                target='_blank'
                rel='noopener'
              >
                Błogosławieństwo kierowców i
                pojazdów - <b>24.07.2022 r.</b>
              </a>
              <br />
              <a
                href='zdjecia/Boze-Cialo-2022'
                target='_blank'
                rel='noopener'
              >
                Uroczystość Najświętszego Ciała i
                Krwi Pańskiej -{' '}
                <b>16.06.2022 r.</b>
              </a>
              <br />
              <a
                href='zdjecia/Odpust-2022'
                target='_blank'
                rel='noopener'
              >
                Uroczystość Odpustowa ku czci
                Chrystusa Dobrego Pasterza -{' '}
                <b>8.05.2022 r.</b>
              </a>
              <br />
              <a
                href='zdjecia/Nabozenstwo-Majowe-w-Plenerze-2022'
                target='_blank'
                rel='noopener'
              >
                Nabożeństwo majowe w plenerze przy
                ul. Iglastej 2 -{' '}
                <b>1.05.2022 r.</b>
              </a>
              <br />
              <a
                href='zdjecia/Wigilia-Paschalna-2022'
                target='_blank'
                rel='noopener'
              >
                Wigilia Paschalna -{' '}
                <b>16.04.2022 r.</b>
              </a>
              <br />
              <a
                href='zdjecia/Blogoslawienstwo-Pokarmow-2022'
                target='_blank'
                rel='noopener'
              >
                Błogosławieństwo potraw -{' '}
                <b>16.04.2022 r.</b>
              </a>
              <br />
              <a
                href='zdjecia/Wielki-Piatek-2022'
                target='_blank'
                rel='noopener'
              >
                Wielki Piątek -{' '}
                <b>15.04.2022 r.</b>
              </a>
              <br />
              <a
                href='zdjecia/Wielki-Czwartek-2022'
                target='_blank'
                rel='noopener'
              >
                Wielki Czwartek -{' '}
                <b>14.04.2022 r.</b>
              </a>
              <br />
              <a
                href='zdjecia/Niedziela-Palmowa-2022'
                target='_blank'
                rel='noopener'
              >
                Niedziela Palmowa -{' '}
                <b>10.04.2022 r.</b>
              </a>
              <br />
              <a
                href='zdjecia/Droga-Krzyzowa-Ulicami-2022'
                target='_blank'
                rel='noopener'
              >
                Droga krzyżowa ulicami naszej
                parafii - <b>8.04.2022 r.</b>
              </a>
              <br />
              <a
                href='zdjecia/Bierzmowanie-2022'
                target='_blank'
                rel='noopener'
              >
                Bierzmowanie -{' '}
                <b>24.03.2022 r.</b>
              </a>
              <br />
              <a
                href='zdjecia/Sroda-Popielcowa-2022'
                target='_blank'
                rel='noopener'
              >
                Środa Popielcowa -{' '}
                <b>2.03.2022 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Ofiarowanie-Panskie-2022'
                target='_blank'
                rel='noopener'
              >
                Święto Ofiarowania Pańskiego -{' '}
                <b>2.02.2022 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Objawienie-Panskie-2022'
                target='_blank'
                rel='noopener'
              >
                Objawienie Pańskie -{' '}
                <b>6.01.2022 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Pasterka-2021'
                target='_blank'
                rel='noopener'
              >
                Pasterka - <b>24.12.2021 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Swiatlo-Betlejemskie-2021'
                target='_blank'
                rel='noopener'
              >
                Przyjęcie światła betlejemskiego w
                naszej parafii -{' '}
                <b>20.12.2021 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Blogoslawienstwo-Wienca-Adwentowego-2021'
                target='_blank'
                rel='noopener'
              >
                Błogosławieństwo wieńca
                adwentowego - <b>28.11.2021 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Wszystkich-Swietych-2021'
                target='_blank'
                rel='noopener'
              >
                Uroczystość Wszystkich Świętych i
                procesja z modlitwą za zmarłych na
                cmentarzu - <b>1.11.2021 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Dzien-Papieski-2021'
                target='_blank'
                rel='noopener'
              >
                Dzień Papieski i wystawa pt. Na
                skrzydłach Miłości -{' '}
                <b>10.10.2021 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Pozegnianie-Dawida-Sewruka'
                target='_blank'
                rel='noopener'
              >
                Pożegnanie księdza Dawida Sewruka
                - <b>29.08.2021 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Blogoslawienstwo-Ziol-2021'
                target='_blank'
                rel='noopener'
              >
                Błogosławieństwo ziół i kwiatów -{' '}
                <b>15.08.2021 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Swiety-Krzysztof-2021'
                target='_blank'
                rel='noopener'
              >
                Błogosławieństwo kierowców i
                pojazdów - <b>25.07.2021 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Bierzmowanie-2021'
                target='_blank'
                rel='noopener'
              >
                Bierzmowanie -{' '}
                <b>24.06.2021 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Pielgrzymka-Rowerowa-Sanktuarium-2021'
                target='_blank'
                rel='noopener'
              >
                Pielgrzymka rowerowa do
                Sanktuarium matki Bożej Trzykroć
                Przedziwnej w Bydgoszczy -{' '}
                <b>20.06.2021 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Boze-Cialo-2021'
                target='_blank'
                rel='noopener'
              >
                Uroczystość Najświętszego Ciała i
                Krwi Chrystusa -{' '}
                <b>3.06.2021 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Dzien-Strazaka-2021'
                target='_blank'
                rel='noopener'
              >
                Dzień Strażaka -{' '}
                <b>2.05.2021 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Wigilia-Paschalna-2021'
                target='_blank'
                rel='noopener'
              >
                Wigilia Paschalna -{' '}
                <b>3.04.2021 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Blogoslawienstwo-Pokarmow-2021'
                target='_blank'
                rel='noopener'
              >
                Błogosławieństwo pokarmów na stół
                wielkanocny - <b>3.04.2021 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Wielki-Piatek-2021'
                target='_blank'
                rel='noopener'
              >
                Liturgia Męki Pańskiej -{' '}
                <b>2.04.2021 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Wielki-Czwartek-2021'
                target='_blank'
                rel='noopener'
              >
                Msza święta Wieczerzy Pańskiej -{' '}
                <b>1.04.2021 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Niedziela-Palmowa-2021'
                target='_blank'
                rel='noopener'
              >
                Niedziela Palmowa -{' '}
                <b>28.03.2021 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Misterium-Meki-Panskiej-2021'
                target='_blank'
                rel='noopener'
              >
                Misterium Męki Pańskie w formie
                słuchowiska - <b>26.03.2021 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Rekolekcje-Wielkopostne-2021'
                target='_blank'
                rel='noopener'
              >
                Rekolekcje wielkopostne
                Prowadzący: ks. dr Jerzy
                Jastrzębski. Temat: Stefan
                Kardynał Wyszyński- inspiracje dla
                Kościoła, parafii i rodziny. -{' '}
                <b>17.02.2021 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Sroda-Popielcowa-2021'
                target='_blank'
                rel='noopener'
              >
                Środa Popielcowa -{' '}
                <b>17.02.2021 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Ofiarowanie-Panskie-2021'
                target='_blank'
                rel='noopener'
              >
                Święto Ofiarowania Pańskiego -{' '}
                <b>2.02.2021 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Objawienie-Panskie-2021'
                target='_blank'
                rel='noopener'
              >
                Uroczystość Objawienia pańskiego -{' '}
                <b>6.01.2021 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Pasterka-2020'
                target='_blank'
                rel='noopener'
              >
                Pasterka - <b>24.12.2020 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Wszystkich-Swietych-2020'
                target='_blank'
                rel='noopener'
              >
                Procesja i modlitwa za zmarłych na
                cmentarzu w oktawie Uroczystości
                Wszystkich Świętych -{' '}
                <b>3.11.2020 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Tydzien-Misyjny-2020'
                target='_blank'
                rel='noopener'
              >
                Zakończenie Tygodnia Misyjnego z
                homilią diakona Godfreda, który
                pochodzi z Ghany, a od 4 lat
                studiuje w Seminarium Księży
                Werbistów w Pieniężnie -{' '}
                <b>25.10.2020 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Jubileusz-40-lecia-Parafii'
                target='_blank'
                rel='noopener'
              >
                Jubileusz 40-lecia erygowania
                naszej parafii -{' '}
                <b>4.10.2020 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Pielgrzymka-Rowerowa-Reformata-2020'
                target='_blank'
                rel='noopener'
              >
                Pielgrzymka rowerowa do
                Drzewianowa pamięci księdza
                proboszcza Włodzimierza Reformata
                - pierwszego proboszcza -{' '}
                <b>3.10.2020 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Wniebowziecia-NMP-2020'
                target='_blank'
                rel='noopener'
              >
                Uroczystość Wniebowzięcia NMP.
                Błogosławieństwo ziół i kwiatów -{' '}
                <b>15.08.2020 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Swiety-Krzysztof-2020'
                target='_blank'
                rel='noopener'
              >
                Błogosławieństwo pojazdów i
                kierowców - <b>27.07.2020 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Bierzmowanie-2020'
                target='_blank'
                rel='noopener'
              >
                Bierzmowanie -{' '}
                <b>25.06.2020 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Koncert-Katarzyna-Szewczyk-2020'
                target='_blank'
                rel='noopener'
              >
                Koncert w wykonaniu muzyków z
                Bydgoszczy pod dyrekcją pani dr
                Katarzy Szewczyk -{' '}
                <b>21.06.2020 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Boze-Cialo-2020'
                target='_blank'
                rel='noopener'
              >
                Boże Ciało - <b>11.06.2020r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Wniebowziecie-Panskie-2020'
                target='_blank'
                rel='noopener'
              >
                Wniebowstąpienie Pańskie -{' '}
                <b>24.05.2020 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Wigilia-Paschalna-2020'
                target='_blank'
                rel='noopener'
              >
                Wigilia Paschalna -{' '}
                <b>11.04.2020 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Wielki-Piatek-2020'
                target='_blank'
                rel='noopener'
              >
                Wielki Piątek -{' '}
                <b>10.04.2020 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Wielki-Czwartek-2020'
                target='_blank'
                rel='noopener'
              >
                Wielki Czwartek -{' '}
                <b>9.04.2020 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Ofiarowanie-Panskie-2020/index.html'
                target='_blank'
                rel='noopener'
              >
                Ofiarowanie Pańskie -{' '}
                <b>2.02.2020r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Objawienie-2020/index.html'
                target='_blank'
                rel='noopener'
              >
                Uroczystość Objawienia Pańskiego -{' '}
                <b>6.01.2020 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Pasterka-2019/index.html'
                target='_blank'
                rel='noopener'
              >
                Pasterka - <b>24.12.2019r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Swiatlo-Betlejemskie-2019/index.html'
                target='_blank'
                rel='noopener'
              >
                Przyjęcie światełka betlejemskiego
                - <b>20.12.2019 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Adwentowa-Niedziela-Rekolekcyjna-2019/index.html'
                target='_blank'
                rel='noopener'
              >
                Adwentowa niedziela rekolekcyjna
                (ks. Florian Cieniuch) -{' '}
                <b>15.12.2019 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Wszystkich-Swietych-2019/index.html'
                target='_blank'
                rel='noopener'
              >
                Wszystkich Świętych -{' '}
                <b>1.11.2019 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Swiety-Krzysztof-2019/index.html'
                target='_blank'
                rel='noopener'
              >
                Błogosławieństwo kierowców i
                pojazdów - <b>25.07.2019 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Boze-Cialo-2019/index.html'
                target='_blank'
                rel='noopener'
              >
                Boże Ciało - <b>20.06.2019 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Odpust-2019/index.html'
                target='_blank'
                rel='noopener'
              >
                Uroczystość Odpustowa połączona z
                jubileuszem 60-lecia kapłaństwa
                księdza kanonika Franciszka
                Sołtysiaka - <b>12.05.2019 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Wielkanoc-2019/index.html'
                target='_blank'
                rel='noopener'
              >
                Wielkanoc - <b>21.04.2019 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Wielka-Sobota-2019/index.html'
                target='_blank'
                rel='noopener'
              >
                Wigilia Paschalna -{' '}
                <b>20.04.2019 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Blogoslawienstwo-Pokarmow-2019/index.html'
                target='_blank'
                rel='noopener'
              >
                Błogosławieństwo Pokarmów -{' '}
                <b>20.04.2019 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Wielki-Piatek-2019/index.html'
                target='_blank'
                rel='noopener'
              >
                Wielki Piątek -{' '}
                <b>19.04.2019 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Wielki-Czwartek-2019/index.html'
                target='_blank'
                rel='noopener'
              >
                Wielki Czwartek -{' '}
                <b>18.04.2019 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Niedziela-Palmowa-2019/index.html'
                target='_blank'
                rel='noopener'
              >
                Niedziela Palmowa -{' '}
                <b>14.04.2019 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Droga-krzyzowa-ulicami-2019/index.html'
                target='_blank'
                rel='noopener'
              >
                Droga krzyżowa ulicami parafii -{' '}
                <b>12.04.2019 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Bierzmowanie-2019/index.html'
                target='_blank'
                rel='noopener'
              >
                Sakrament Bierzmowania -{' '}
                <b>10.04.2019 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Piesni-Pasyjne-2019/index.html'
                target='_blank'
                rel='noopener'
              >
                Koncert pieśni pasyjnych -{' '}
                <b>7.04.2019 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Sroda-Popielcowa-2019/index.html'
                target='_blank'
                rel='noopener'
              >
                Środa Popielcowa -{' '}
                <b>6.03.2019 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Pozegnanie-Obrazu-2019/index.html'
                target='_blank'
                rel='noopener'
              >
                Pożegnanie Obrazu Matki Bożej
                Jasnogórskiej -{' '}
                <b>03.03.2019 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Apel-Jasnogorski-2019/index.html'
                target='_blank'
                rel='noopener'
              >
                Apel Jasnogórski i Maryjna
                Pasterka - <b>2.03.2019 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Peregrynacja-2019/index.html'
                target='_blank'
                rel='noopener'
              >
                Peregrynacja - <b>1.03.2019 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Misje-Zakonczenie-2019/index.html'
                target='_blank'
                rel='noopener'
              >
                Zakończenie Misji Świętych -{' '}
                <b>1.03.2019 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Apel-2019/index.html'
                target='_blank'
                rel='noopener'
              >
                Apel Jasnogórski-{' '}
                <b>1.03.2019 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Odnowienie-2019/index.html'
                target='_blank'
                rel='noopener'
              >
                Odnowienie przyrzeczeń małżeńskich
                - <b>28.02.2019 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Misje-Medaliku-2019/index.html'
                target='_blank'
                rel='noopener'
              >
                Przyjęcie Cudownego Medaliku -{' '}
                <b>27.02.2019 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Misje-2019/index.html'
                target='_blank'
                rel='noopener'
              >
                Rozpoczęcie Misji Świętych -{' '}
                <b>23.02.2019 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Przedstawienie-2019/index.html'
                target='_blank'
                rel='noopener'
              >
                Przedstawienie pt. "Babciu,
                dziadku nie daj się oszukać" -{' '}
                <b>3.02.2019 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Ofiarowanie-Panskie-2019/index.html'
                target='_blank'
                rel='noopener'
              >
                Ofiarowanie Pańskie -{' '}
                <b>2.02.2019 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Objawienie-Panskie-2019/index.html'
                target='_blank'
                rel='noopener'
              >
                Uroczystość Objawienia Pańskiego -{' '}
                <b>6.01.2019 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Niepodleglosc-2018/index.html'
                target='_blank'
                rel='noopener'
              >
                100 lat Niepodległości -{' '}
                <b>11.11.2018 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Wszystkich-2018/index.html'
                target='_blank'
                rel='noopener'
              >
                Wszystkich Świętych -{' '}
                <b>1.11.2018 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Witraz-2018/index.html'
                target='_blank'
                rel='noopener'
              >
                Poświęcenie Witrażu
                przedstawiającego 12 Apostołów,
                ufundowany przez Rodzyny z Parafii
                - <b>14.10.2018 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Koncert-Niepodleglosc-2018/index.html'
                target='_blank'
                rel='noopener'
              >
                Koncert z okazji 100-tej Rocznicy
                Odyzskania przez Polskę
                Niepodległości -{' '}
                <b>30.09.2018 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Rezurekcja-2018/index.html'
                target='_blank'
                rel='noopener'
              >
                Rezurekcja - <b>1.04.2018 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Wielki-Czwartek-2018/index.html'
                target='_blank'
                rel='noopener'
              >
                Wielki Czwartek -{' '}
                <b>29.03.2018 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Bierzmowanie-2018/index.html'
                target='_blank'
                rel='noopener'
              >
                Bierzmowanie -{' '}
                <b>26.02.2018 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Wizytacja-2018/index.html'
                target='_blank'
                rel='noopener'
              >
                Wizytacja kanoniczna Księdza
                Biskupa Jana Tyrawy -{' '}
                <b>26.02.2018 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Koledowanie-2018/index.html'
                target='_blank'
                rel='noopener'
              >
                Koncert laureatów Gminnego
                Konkursu kolęd i Pastorałek -{' '}
                <b>2018 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Jaselka-2018/index.html'
                target='_blank'
                rel='noopener'
              >
                Jasełka - <b>14.01.2018 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Imieniny-Ks-Dawida-2017/index.html'
                target='_blank'
                rel='noopener'
              >
                Imieniny ks. Dawida -{' '}
                <b>31.12.2017 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Pasterka-2017/index.html'
                target='_blank'
                rel='noopener'
              >
                Pasterka z żywym żłóbkiem -{' '}
                <b>24.12.2017 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Swiatlo-Betlejemskie-2017/index.html'
                target='_blank'
                rel='noopener'
              >
                Betlejemskie światło pokoju -{' '}
                <b>21.12.2017 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Adwent-2017/index.html'
                target='_blank'
                rel='noopener'
              >
                Błogosławieństwo wieńca
                adwentowego - <b>3.12.2017 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Imieniny-Ks-Edwarda-2017/index.html'
                target='_blank'
                rel='noopener'
              >
                Imieniny ks. Edwarda -{' '}
                <b>15.10.2017 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Koncert-2017/index.html'
                target='_blank'
                rel='noopener'
              >
                Koncert pieśni maryjnych -{' '}
                <b>13.10.2017 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Blogoslawienstwo-Fatimskie-2017/index.html'
                target='_blank'
                rel='noopener'
              >
                Błogosławieństwo figury i I
                Nabożeństwo Farimskie -{' '}
                <b>13.10.2017 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Wszystkich-Swietych-2017/index.html'
                target='_blank'
                rel='noopener'
              >
                Wszystkich Świętych -{' '}
                <b>1.11.2017 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Schola-2017/index.html'
                target='_blank'
                rel='noopener'
              >
                Niedzielny występ scholii -{' '}
                <b>29.10.2017 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Rozaniec-2017/index.html'
                target='_blank'
                rel='noopener'
              >
                Błogosławieństwo różańców -{' '}
                <b>8.10.2017 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Blogoslawienstwo-Choragwi-2017/index.html'
                target='_blank'
                rel='noopener'
              >
                Błogosławieństwo chorągwi -{' '}
                <b>7.10.2017 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Isrka-Milosierdzia-2017/index.html'
                target='_blank'
                rel='noopener'
              >
                Iskra Miłosierdzia -{' '}
                <b>25.09.2017 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Wniebowziecie-NMP-2017/index.html'
                target='_blank'
                rel='noopener'
              >
                Wniebowzięcie Najświętszej Marii
                Panny - <b>15.08.2017 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Swiety-Krzysztof-2017/index.html'
                target='_blank'
                rel='noopener'
              >
                Błogosławieństwo kierowców i
                pojazdów - <b>25.07.2017 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Wprowadzenie-2017/index.html'
                target='_blank'
                rel='noopener'
              >
                Liturgiczne wprowadzenie w urząd
                proboszcza ks. Edwarda
                Wasilewskiego -{' '}
                <b>9.07.2017 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Boze-Cialo-2017/index.html'
                target='_blank'
                rel='noopener'
              >
                Boże Ciało - <b>15.06.2017 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Triduum-2017/czwartek/index.html'
                target='_blank'
                rel='noopener'
              >
                Wielki Czwartek -{' '}
                <b>13.04.2017 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Triduum-2017/piatek/index.html'
                target='_blank'
                rel='noopener'
              >
                Wielki Piątek -{' '}
                <b>14.04.2017 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Triduum-2017/sobota/index.html'
                target='_blank'
                rel='noopener'
              >
                Wielka Sobota -{' '}
                <b>15.05.2017 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Triduum-2017/wielkanoc/index.html'
                target='_blank'
                rel='noopener'
              >
                Wigilia Paschalna -{' '}
                <b>15.05.2017 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Niedziela-Palmowa-2017/index.html'
                target='_blank'
                rel='noopener'
              >
                Niedziela Palmowa -{' '}
                <b>9.04.2017 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Bierzmowanie-2017/index.html'
                target='_blank'
                rel='noopener'
              >
                Sakrament Bierzmowania -{' '}
                <b>27.03.2017 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Ministranci-2017/index.html'
                target='_blank'
                rel='noopener'
              >
                Promocja Ministrantów -{' '}
                <b>20.03.2017 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Sroda-Popielcowa-2017/index.html'
                target='_blank'
                rel='noopener'
              >
                Środa Popielcowa -{' '}
                <b>1.03.2017 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Zolnierze-2017/index.html'
                target='_blank'
                rel='noopener'
              >
                Święto Żołnierza Wyklętego -{' '}
                <b>26.02.2017 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Ofiarowanie-Panskie-2017/index.html'
                target='_blank'
                rel='noopener'
              >
                Ofiarowanie Pańskie -{' '}
                <b>2.02.2017 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Objawienie-Panskie-2017/index.html'
                target='_blank'
                rel='noopener'
              >
                Objawienie Pańskie -{' '}
                <b>6.01.2017 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Koledowanie-2016/index.html'
                target='_blank'
                rel='noopener'
              >
                Wspólne kolędowanie -{' '}
                <b>26.12.2016 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Narodzenie-2016/index.html'
                target='_blank'
                rel='noopener'
              >
                Narodzenie Pańskie -{' '}
                <b>25.12.2016 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Roraty-2016/index.html'
                target='_blank'
                rel='noopener'
              >
                Roraty - <b>13.12.2016 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Swiatlo-Betlejemskie-2016/index.html'
                target='_blank'
                rel='noopener'
              >
                Światełko Betlejemskie -{' '}
                <b>12.12.2016 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Blogoslawienstwo-Figurek-2016/index.html'
                target='_blank'
                rel='noopener'
              >
                Błogosławieństwo figurek
                Dzieciątka i stajenek -{' '}
                <b>11.12.2016 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Wszystkich-2016/index.html'
                target='_blank'
                rel='noopener'
              >
                Wszystkich Świętych -{' '}
                <b>1.11.2016 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Rozaniec-2016/index.html'
                target='_blank'
                rel='noopener'
              >
                Różaniec z dziećmi -{' '}
                <b>24.10.2016 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Nabozentwo-Przeblagalne-2016/index.html'
                target='_blank'
                rel='noopener'
              >
                Nabożeństwo Przebłagalne
                (Renowacja Misji) -{' '}
                <b>14.09.2016 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Blogoslawienstwo-Dzieci-2016/index.html'
                target='_blank'
                rel='noopener'
              >
                Błogosławieństwo Dzieci (Renowacja
                Misji) - <b>11.09.2016 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Modlitwa-2016/index.html'
                target='_blank'
                rel='noopener'
              >
                Modlitwa przy Krzyżu Misyjnym
                (Renowacja Misji) -{' '}
                <b>11.09.2016 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Renowacje-2016/index.html'
                target='_blank'
                rel='noopener'
              >
                Rozpoczęcie Renowacja Misji
                Świętych - <b>10.09.2016 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Wniebowziecie-NMP-2016/index.html'
                target='_blank'
                rel='noopener'
              >
                Wniebowzięcie Najświętszej Marii
                Panny - <b>15.08.2016 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Swiety-Krzysztof-2016/index.html'
                target='_blank'
                rel='noopener'
              >
                Błogosławieństwo kierowców i
                pojazdów - <b>24.07.2016 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Powitanie-Ks-Dawida-2016/index.html'
                target='_blank'
                rel='noopener'
              >
                Powitanie ks. Dawida -{' '}
                <b>27.06.2016 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/NSPJ-2016/index.html'
                target='_blank'
                rel='noopener'
              >
                Uroczystość Najświętszego Serca
                Pana Jezusa - <b>3.06.2016 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Boze-Cialo-2016/index.html'
                target='_blank'
                rel='noopener'
              >
                Boże Ciało - <b>26.05.2016 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Prymicje-Adama/index.html'
                target='_blank'
                rel='noopener'
              >
                Prymicje ks. Adama Stachowiaka -{' '}
                <b>22.05.2016 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Pierwsza-Komunia-2016/index.html'
                target='_blank'
                rel='noopener'
              >
                Pierwsza Komunia Św. -{' '}
                <b>15.05.2016 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Prace-Brukarskie-2016/index.html'
                target='_blank'
                rel='noopener'
              >
                Prace Brukarskie -{' '}
                <b>22-25.04.2016 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Odpust-2016/index.html'
                target='_blank'
                rel='noopener'
              >
                Odpust - <b>17.04.2016 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Triduum-2016/czwartek/index.html'
                target='_blank'
                rel='noopener'
              >
                Wielki Czawartek -{' '}
                <b>24.03.2016 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Triduum-2016/piatek/index.html'
                target='_blank'
                rel='noopener'
              >
                Wielki Piątek -{' '}
                <b>25.03.2016 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Triduum-2016/sobota/index.html'
                target='_blank'
                rel='noopener'
              >
                Wielka Sobota -{' '}
                <b>26.03.2016 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Triduum-2016/paschalna/index.html'
                target='_blank'
                rel='noopener'
              >
                Wigilia Paschalna -{' '}
                <b>26.03.2016 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Niedziela-Palmowa/index.html'
                target='_blank'
                rel='noopener'
              >
                Niedziela Palmowa -{' '}
                <b>20.03.2016 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Ministranci-2016/index.html'
                target='_blank'
                rel='noopener'
              >
                Promocja Ministrantów -{' '}
                <b>19.03.2016 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Bierzmowanie-2016/index.html'
                target='_blank'
                rel='noopener'
              >
                Sakrament Bierzmowania -{' '}
                <b>7.03.2016 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Zolnierze-2016/index.html'
                target='_blank'
                rel='noopener'
              >
                Święto Żołnierza Wyklętego -{' '}
                <b>28.02.2016 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Sroda-Popielcowa-2016/index.html'
                target='_blank'
                rel='noopener'
              >
                Środa Popielcowa -{' '}
                <b>10.02.2016 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Agata-2016/index.html'
                target='_blank'
                rel='noopener'
              >
                Błogosławieństwo wody i chleba Św.
                Agaty - <b>5.02.2016 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Ofiarowanie-Panskie-2016/index.html'
                target='_blank'
                rel='noopener'
              >
                Ofiarowanie Pańskie -{' '}
                <b>2.02.2016 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Peregrynacja-Symboli-2016/index.html'
                target='_blank'
                rel='noopener'
              >
                Peregrynacja Symboli ŚDM -{' '}
                <b>15.01.2016 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Objawienie-Panskie-2016/index.html'
                target='_blank'
                rel='noopener'
              >
                Objawienie Pańskie -{' '}
                <b>6.01.2016 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Koledowanie-2015/index.html'
                target='_blank'
                rel='noopener'
              >
                Wspólne kolędowanie -{' '}
                <b>26.12.2015 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Narodzenie-2015/index.html'
                target='_blank'
                rel='noopener'
              >
                Narodzenie Pańskie -{' '}
                <b>25.12.2015 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Blogoslawienstwo-Figurek-2015/index.html'
                target='_blank'
                rel='noopener'
              >
                Błogosławieństwo figurek
                Dzieciątka i stajenek -{' '}
                <b>13.12.2015 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Wszystkich-2015/index.html'
                target='_blank'
                rel='noopener'
              >
                Wszystkich Świętych -{' '}
                <b>1.11.2015 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Misja-Zakonczenie-2015/index.html'
                target='_blank'
                rel='noopener'
              >
                Zakończenie Misji Świętych -{' '}
                <b>20.09.2015 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Odnowienie-2015/index.html'
                target='_blank'
                rel='noopener'
              >
                Odnowanienie ślubowań małżeńskich
                (Misje) - <b>19.09.2015 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Misje-2015/index.html'
                target='_blank'
                rel='noopener'
              >
                Dzień sakramentów Pokuty i
                Eucharystii (Misje) -{' '}
                <b>17.09.2015 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Nabozentwo-Przeblagalne-2015/index.html'
                target='_blank'
                rel='noopener'
              >
                Nabożeństwo przebłagalne (Misje) -{' '}
                <b>14.09.2015 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Modlitwa-2015/index.html'
                target='_blank'
                rel='noopener'
              >
                Modlitwa za zmarłych (Misje) -{' '}
                <b>13.09.2015 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Misje-Rozpoczecie-2015/index.html'
                target='_blank'
                rel='noopener'
              >
                Rozpoczęcie Misji Świętych -{' '}
                <b>13.09.2015 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Wniebowziecie-NMP-2015/index.html'
                target='_blank'
                rel='noopener'
              >
                Uroczystość Wniebowzięcia
                Najświętszej Marii Panny -{' '}
                <b>15.08.2015 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Swiety-Krzysztof-2015/index.html'
                target='_blank'
                rel='noopener'
              >
                Błogosławieństwo kierowców i
                pojazdów - <b>25.07.2015 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Antoni-2015/index.html'
                target='_blank'
                rel='noopener'
              >
                Wieczór ze Świętym Antonim -{' '}
                <b>13.06.2015 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Boze-Cialo-2015/index.html'
                target='_blank'
                rel='noopener'
              >
                Boże Ciało - <b>2015 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Pierwsza-Komunia-2015/index.html'
                target='_blank'
                rel='noopener'
              >
                Pierwsza Komunia -{' '}
                <b>17.05.2015 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Odpust-2015/index.html'
                target='_blank'
                rel='noopener'
              >
                Odpust - <b>26.04.2015 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Triduum-2015/czwartek/index.html'
                target='_blank'
                rel='noopener'
              >
                Wielki Czwartek -{' '}
                <b>2.04.2015 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Triduum-2015/piatek/index.html'
                target='_blank'
                rel='noopener'
              >
                Wielki Piątek -{' '}
                <b>3.04.2015 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Triduum-2015/sobota/index.html'
                target='_blank'
                rel='noopener'
              >
                Wielka Sobota -{' '}
                <b>4.04.2015 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Triduum-2015/paschalna/index.html'
                target='_blank'
                rel='noopener'
              >
                Wigilia Paschalna -{' '}
                <b>4.04.2015 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Niedziela-Palmowa-2015/index.html'
                target='_blank'
                rel='noopener'
              >
                Niedziela Palmowa -{' '}
                <b>29.03.2015 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Wycieczka/index.html'
                target='_blank'
                rel='noopener'
              >
                Wycieczka ministrant do Trójmiasta
                - <b>21-22.03.2015 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Ministranci-2015/index.html'
                target='_blank'
                rel='noopener'
              >
                Promocja ministrantów -{' '}
                <b>19.03.2015 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Bierzmowanie-2015/index.html'
                target='_blank'
                rel='noopener'
              >
                Bierzmowanie -{' '}
                <b>16.03.2015 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Prace-Brukarskie-2015/index.html'
                target='_blank'
                rel='noopener'
              >
                Prace brukarskie -{' '}
                <b>7-11.03.2015 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Zolnierze-2015/index.html'
                target='_blank'
                rel='noopener'
              >
                Święto Żołnierza Wyklętego -{' '}
                <b>1.03.2015 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Sroda-Popielcowa-2015/index.html'
                target='_blank'
                rel='noopener'
              >
                Popielec - <b>18.02.2015 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Blogoslawienstwo-Chleba-2015/index.html'
                target='_blank'
                rel='noopener'
              >
                Błogosławieństwo chleba i wody
                Świętej Agaty -{' '}
                <b>5.02.2015 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Ofiarowanie-Panskie-2015/index.html'
                target='_blank'
                rel='noopener'
              >
                Ofiarowanie Pańskie -{' '}
                <b>2.02.2015 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Objawienie-Panskie-2015/index.html'
                target='_blank'
                rel='noopener'
              >
                Objawienie Pańskie -{' '}
                <b>6.01.2015 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Koledowanie-2014/index.html'
                target='_blank'
                rel='noopener'
              >
                Wspólne kolędowanie -{' '}
                <b>26.12.2014 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Narodzenie-2014/index.html'
                target='_blank'
                rel='noopener'
              >
                Narodzenie Pańskie -{' '}
                <b>25.12.2014 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Blogoslawienie-Stajenki-2014/index.html'
                target='_blank'
                rel='noopener'
              >
                Błogosławieństwo figurek
                Dzieciątka i stajenek -{' '}
                <b>14.12.2014 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Rekolekcje-2014/index.html'
                target='_blank'
                rel='noopener'
              >
                Rekolekcje Adwentowe -{' '}
                <b>8.12.2014 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Adwent-2014/index.html'
                target='_blank'
                rel='noopener'
              >
                I Niedziela Adwentu -{' '}
                <b>30.11.2014 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Niepodleglosc-2014/index.html'
                target='_blank'
                rel='noopener'
              >
                Święto Niepodległości -{' '}
                <b>11.11.2014 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Wycieczka-2014/index.html'
                target='_blank'
                rel='noopener'
              >
                Wycieczka ministrantów do
                Trójmiasta -{' '}
                <b>9-10.11.2014 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Dzien-Misyjny-2014/index.html'
                target='_blank'
                rel='noopener'
              >
                Dzień Misyjny - O. Guillermo
                Aguinaga Pantoja (Kombonianin) -{' '}
                <b>25.09.2014 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Podwyzszenie-Krzyza-2014/index.html'
                target='_blank'
                rel='noopener'
              >
                Święto Podwyższenia Krzyża Św. -{' '}
                <b>14.09.2014 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Boze-Cialo-2014/index.html'
                target='_blank'
                rel='noopener'
              >
                Boże Ciało - <b>19.06.2014 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Dni-Krzyzowe-2014/index.html'
                target='_blank'
                rel='noopener'
              >
                Dni krzyżowe -{' '}
                <b>24.05.2014 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Odpust-2014/index.html'
                target='_blank'
                rel='noopener'
              >
                Odpust i dziękczynienie za
                Kanonizację - <b>11.05.2014 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Triduum-2014/czwartek/index.html'
                target='_blank'
                rel='noopener'
              >
                Wielki Czwartek -{' '}
                <b>17.04.2014 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Triduum-2014/piatek/index.html'
                target='_blank'
                rel='noopener'
              >
                Wielki Piątek -{' '}
                <b>18.04.2014 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Blogoslawienstwo-Pokarmow-2014/index.html'
                target='_blank'
                rel='noopener'
              >
                Wielka Sobota -{' '}
                <b>19.04.2014 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Triduum-2014/paschalna/index.html'
                target='_blank'
                rel='noopener'
              >
                Wigilia Paschalna -{' '}
                <b>19.04.2014 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Niedziela-Palmowa-2014/index.html'
                target='_blank'
                rel='noopener'
              >
                Niedziela Palmowa -{' '}
                <b>13.04.2014 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Bierzmowanie-2014/index.html'
                target='_blank'
                rel='noopener'
              >
                Sakrament Bierzmowania -{' '}
                <b>31.03.2014 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Podziekowanie-Lukasz/index.html'
                target='_blank'
                rel='noopener'
              >
                {' '}
                Podziękowanie ks. Łukaszowi -{' '}
                <b>26.06.2013 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Swiety-Krzysztof-2013/index.html'
                target='_blank'
                rel='noopener'
              >
                Błogosławieństwo kierowców i
                pojazdów - <b>2013 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Boze-Cialo-2013/index.html'
                target='_blank'
                rel='noopener'
              >
                Boże Ciało - <b>30.05.2013 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Wizytacja-2013/index.html'
                target='_blank'
                rel='noopener'
              >
                Wizytacja - <b>2.06.2013 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Pierwsza-Komunia-2013/index.html'
                target='_blank'
                rel='noopener'
              >
                Pierwsza Komunia -{' '}
                <b>20.05.2013 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Triduum-2013/index.html'
                target='_blank'
                rel='noopener'
              >
                Triduum Paschalne - <b>2013 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Odpust-2013/index.html'
                target='_blank'
                rel='noopener'
              >
                Odpust - <b>21.04.2013 r.</b>
              </a>
              <br />

              <a
                href='zdjecia/Wprowadzenie-2012/index.html'
                target='_blank'
                rel='noopener'
              >
                Wprowadzenie - <b>8.07.2012 r.</b>
              </a>
              <br />
            </p>
          </article>
          <img
            className='basic-wire'
            src={basicWire}
            alt='fala'
          />
        </main>
        <Footer />
      </div>
    </div>
  );
};

export default Galeria;
