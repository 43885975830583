import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { FaTimes } from 'react-icons/fa';

import { AppContext } from '../AppContext';

import './Sidebar.scss';

const Sidebar = () => {
  const { isSidebarOpen } =
    useContext(AppContext);
  const { toggleSidebar } =
    useContext(AppContext);

  return (
    <>
      <aside
        className={`aside-main ${
          isSidebarOpen ? 'aside-main-open' : ''
        }`}
      >
        <div
          className='aside-main-icon'
          onClick={toggleSidebar}
        >
          <FaTimes />
        </div>
        <div
          className={`aside-main-container ${
            isSidebarOpen ? 'aside-main-open' : ''
          }`}
        >
          <ul className='aside-main-menu'>
            <Link
              to='/aktualnosci'
              className='aside-main-menu-link'
              onClick={toggleSidebar}
            >
              Aktualności
            </Link>
            <Link
              to='/intencje'
              className='aside-main-menu-link'
              onClick={toggleSidebar}
            >
              Intencje
            </Link>
            <Link
              to='/liturgia'
              className='aside-main-menu-link'
              onClick={toggleSidebar}
            >
              Liturgia
            </Link>
            <Link
              to='/sakramenty'
              className='aside-main-menu-link'
              onClick={toggleSidebar}
            >
              Sakramenty
            </Link>
            <Link
              to='/wspolnoty'
              className='aside-main-menu-link'
              onClick={toggleSidebar}
            >
              Wspólnoty
            </Link>
            <Link
              to='/galeria'
              className='aside-main-menu-link'
              onClick={toggleSidebar}
            >
              Galeria
            </Link>
          </ul>
          <div className='aside-main-button'>
            <Link
              to='/kontakt'
              className='aside-main-button-link'
              onClick={toggleSidebar}
            >
              Kontakt
            </Link>
          </div>
        </div>
      </aside>
    </>
  );
};

export default Sidebar;
