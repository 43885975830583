import React from 'react';

import Navbar from '../../components/Navbar';
import Sidebar from '../../components/Sidebar';
import Footer from '../../components/Footer';
import ScrollButton from '../../utilities/ScrollButton';

import jesus from '../../images/jesus.svg';
import cloudLeft from '../../images/cloud-left.svg';
import cloudRight from '../../images/cloud-right.svg';
import bigCrosses from '../../images/big-crosses.svg';
import waveRed from '../../images/landing-page-wave-red.svg';
import waveBrown from '../../images/landing-page-wave-brown.svg';
import waveLightRed from '../../images/landing-page-wave-light-red.svg';
import basicWire from '../../images/basic-wire.svg';
import plan from '../../images/planspowiedzi.jpg';

import './Home.scss';
import { MdPadding } from 'react-icons/md';

const Home = () => {
  return (
    <div className='animation-wrapper'>
      <main className='default-container animation-section'>
        <Sidebar />
        <Navbar />

        <h1 className='landing-page-title'>
          <span className='first'>
            Parafia Chrystusa Dobrego Pasterza
          </span>{' '}
          w{' '}
          <span className='second'>
            Białych Błotach
          </span>
        </h1>

        <img
          src={cloudLeft}
          alt='chmura'
          className='landing-page-cloud left'
        />
        <img
          src={cloudRight}
          alt='chmura'
          className='landing-page-cloud right'
        />

        <img
          src={jesus}
          alt='Jezus'
          className='landing-page-jesus'
        />

        <img
          src={bigCrosses}
          alt='krzyże'
          className='landing-page-big-crosses'
        />

        <img
          src={waveLightRed}
          alt='jasno czerwona fala'
          className='landing-page-wave light-red'
        />
        <img
          src={waveRed}
          alt='czerwona fala'
          className='landing-page-wave red'
        />
        <img
          src={waveBrown}
          alt='brazowa fala'
          className='landing-page-wave brown'
        />

        <ScrollButton />
      </main>
      <section className='animation-section'>
        <div className='default-container'>
          <Navbar />
          <Sidebar />
          <article className='default-content-wrapper-without-img'>
            <h2 className='subpage-title-center'>
              Ogłoszenia parafialne
            </h2>
            <h3 className='ogloszenia-title'>
              XXIV Niedziela Zwykła
            </h3>
            <h4 className='ogloszenia-date'>
              15 września 2024 r.
            </h4>
            <br />
            <ol
              type='1'
              start='1'
              className='ogloszenia-list'
            >
              <li>
                Z całego serca dziękujemy za
                ofiary składane na tacę tradycyjną
                i elektroniczną oraz za dodatkowe
                ofiary na cele gospodarczo –
                remontowe w tym tygodniu od
                rodziny z ul. Guliwera 500 zł.,
                ul. Cynowej 400 zł, ul. Betonowej
                200 zł., ul. Chlebowej 100 zł.,
                ul. Kadetów 100 zł i od kolejnej
                rodziny z ul. Chlebowej 100 zł.
                Serdecznie dziękujemy dzisiaj za
                ofiary składane na tacę remontową
                z racji III niedzieli miesiąca.
                Bóg zapłać!
              </li>
              <li>
                Zapraszamy chętnych na ostatnią
                już pielgrzymkę do korzeni. Wyjazd
                do Pelplina zaplanowany jest na
                dzień 28 września w podobnej
                formule jak wcześniejsze do
                Gniezna i Kołobrzegu (zwiedzanie
                katedry, muzeum, uroczysta
                Eucharystia, obiad). Koszt około
                50 zł przy założeniu, że będzie
                pełen autokar. Zapisy przyjmujemy
                do środy 18 września. Serdecznie
                zapraszamy.
              </li>
              <li>
                W tym tygodniu w liturgii czcimy:
                <br />• w poniedziałek – św.
                Korneliusza, papieża i św.
                Cypriana, biskupa – męczenników,
                <br />• w środę – św. Stanisława
                Kostkę, zakonnika, patrona
                młodzieży. Z tej okazji w sobotę
                21 września o godz. 11.00
                zapraszamy na spotkanie w salce
                (ministrantów, lektorów oraz
                scholkę),
                <br />• w piątek – św. Andrzeja
                Kim Taegon oraz Towarzyszy
                męczeństwa,
                <br />• w sobotę – św. Mateusza,
                Apostoła i Ewangelisty.
              </li>
              <li>
                We wtorki po wieczornej Mszy
                Świętej zapraszamy chętnych na
                otwarte spotkanie modlitewne
                prowadzone przez wspólnotę „Nowe
                Przymierze”. W czwartek po
                wieczornej Mszy Świętej około
                18.30 zapraszamy na adorację
                Najświętszego Sakramentu z
                modlitwą do Chrystusa Dobrego
                Pasterza.
              </li>
              <li>
                Jest dostępna księga intencji na
                przyszły rok 2025. Rezerwować Msze
                Święte w godzinach biura
                parafialnego (wtorek i czwartek od
                16.00 do 17.00) oraz po każdej
                Mszy Świętej. Prosimy przy
                rezerwacji o podawanie swojego
                numeru telefonu co ułatwi nam
                kontakt w przypadku braku treści
                intencji lub zmiany terminu.
                Przypominamy, że w środy o godz.
                18.00 odprawiamy Mszę zbiorową w
                różnych przedstawianych
                intencjach.
              </li>
              <li>
                Przypominamy, że Msza Święta ze
                szczególnym udziałem dzieci
                odprawiamy o godz. 12.00, w każdą
                niedzielę i uroczystości. W
                niedzielę 29 września po Mszy
                Świętej o godz. 12.00 zapraszamy
                rodziców dzieci przygotowujących
                się do I Komunii Świętej na
                spotkanie w kościele. Natomiast
                młodzież klas 8 oraz klas 1 szkół
                ponadpodstawowych przygotowującą
                się do sakramentu bierzmowania
                zapraszamy również na spotkanie
                wraz z rodzicami 29 września po
                Mszy Świętej o godz. 18.00.
              </li>
              <li>
                W najbliższy piątek 20 września
                Mszą Świętą o godz. 18.00
                rozpocznie kolejny rok formacji
                wspólnota „SYCHAR”. Zapraszamy
                wszystkie małżeństwa przeżywające
                kryzys lub trudności w relacjach.
                Jest to wspólnota, która daje
                również wsparcie osobą będącym w
                separacji lub po rozwodzie,
                Serdecznie zapraszamy.
              </li>
              <li>
                Zachęcamy do czytania prasy
                katolickiej.
              </li>
              <li>
                W minionym tygodniu odszedł do
                Pana:
                <br />+ Ryszard Bieniaszewski, lat
                74, zam. ul. Drzewnej. Pogrzeb
                zmarłego w środę 18 września o
                godz. 13.00 na cmentarzu w Białych
                Błotach.
                <br />
                …wieczny odpoczynek racz mu dać
                Panie…
              </li>
            </ol>
            {/* <img
              src={plan}
              className='default-border planspowiedzi'
              alt='Plan Spowiedzi'
            ></img> */}
          </article>
          {/* <article className='default-content-wrapper-without-img'>
            <h2 className='subpage-title-center'>
              Plan wizyty duszpasterskiej w roku
              2024
            </h2>
            <h4 className='ogloszenia-title koleda-center '>
              Rozpoczynamy w dni powszednie o
              godz. 16.00, w sobote o godz. 11:00.
            </h4>
            <br />
            <br />
            <br /> */}
          {/* <table
              style={{
                width: '100%',
                textAlign: 'center',
                margin: 0,
                padding: 0,
              }}
              class='ogloszenia-list koleda-table'
            >
              <thead>
                <tr>
                  <th>Data</th>
                  <th>Ulica</th>
                  <th>Ulica</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>31.01 2023 r.</td>
                  <td>Gronowa, Czwartaków</td>
                  <td>
                    Czarnoleska, Chełmska, Cukrowa
                  </td>
                </tr>
              </tbody>
            </table> */}
          {/* <table
              style={{
                width: '100%',
                textAlign: 'center',
                margin: 0,
                padding: 0,
              }}
              class='ogloszenia-list koleda-table'
            >
              <thead>
                <tr>
                  <th>Data</th>
                  <th>Ulica</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>2.01, 16:00</td>
                  <td>
                    ul. Betonowa blok nr 2, ul.
                    Berberysowa, ul. Bałkańska,
                    ul. Bagatela
                  </td>
                </tr>
                <tr>
                  <td>3.01, 16:00</td>
                  <td>
                    ul. Betonowa blok nr 4 i blok
                    nr 6 oraz ul. Bracka, ul.
                    Bartnicza
                  </td>
                </tr>
                <tr>
                  <td>4.01, 16:00</td>
                  <td>
                    ul. Betonowa domki, ul.
                    Altanowa, ul. Alibaby, ul.
                    Szubińska nr 4-12, ul. Biała,
                    ul. Betonowa 1a
                  </td>
                </tr>
                <tr>
                  <td>5.01, 16:00</td>
                  <td>
                    ul. Azalowa, ul. Ametystowa,
                    ul. Asfaltowa, ul. Jaśminowa,
                    ul. Leśna, ul. Konwaliowa, ul.
                    Szafirowa, ul. Tulipanowa, ul.
                    Żonkilowa, ul. Różana
                  </td>
                </tr>
                <tr>
                  <td>8.01, 16:00</td>
                  <td>
                    ul. Chmielna, ul. Chmielarska,
                    ul. Czarnoleska, ul. Chełmska,
                    ul. Cukrowa
                  </td>
                </tr>
                <tr>
                  <td>9.01, 16:00</td>
                  <td>
                    ul. Chlebowa 7-83, ul.
                    Iglasta, ul. Izoldy, ul.
                    Cedrowa, ul. Cyprysowa
                  </td>
                </tr>
                <tr>
                  <td>10.01, 16:00</td>
                  <td>
                    ul. Chlebowa 2-76, ul.
                    Gronowa, ul. Czwartaków
                  </td>
                </tr>
                <tr>
                  <td>11.01, 16:00</td>
                  <td>
                    ul. Chlebowa 85-105, ul.
                    Boruty, ul. Zawiła, ul.
                    Czeremchy, ul. Cynowa, ul.
                    Daliowa
                  </td>
                </tr>
                <tr>
                  <td>12.01, 16:00</td>
                  <td>
                    ul. Czysta, ul. Bajeczna, ul.
                    Czekoladow
                  </td>
                </tr>
                <tr>
                  <td>13.01, 11:00</td>
                  <td>
                    ul. Chudoby, ul. Cukiernicza,
                    ul. Ulana
                  </td>
                </tr>
                <tr>
                  <td>15.01, 16:00</td>
                  <td>ul. Gontowa, ul. Gwarna</td>
                </tr>
                <tr>
                  <td>16.01, 16:00</td>
                  <td>
                    ul. Drzewna, ul. Czajcza, ul.
                    Zeusa
                  </td>
                </tr>
                <tr>
                  <td>17.01, 16:00</td>
                  <td>
                    ul. Duńska, ul. Ezopa, ul.
                    Daleka, ul. Żaków, ul. Cała
                  </td>
                </tr>
                <tr>
                  <td>18.01, 16:00</td>
                  <td>
                    ul. Guliwera, ul. Modra, ul.
                    Letnia, ul. Moczary
                  </td>
                </tr>
                <tr>
                  <td>19.01, 16:00</td>
                  <td>
                    ul. Ostróżki, ul. Szubińska 87
                    E, ul. Przemysłowa, ul.
                    Wierzbowa, ul. Zacisze, ul.
                    Kapliczna, ul. Szubińska
                  </td>
                </tr>
                <tr>
                  <td>20.01, 11:00</td>
                  <td>
                    ul. Centralna 2-66 i 9-41a,
                    ul. Cisowa, ul. Centralna 47 i
                    68 do 110 i 113
                  </td>
                </tr>
                <tr>
                  <td>22.01, 16:00</td>
                  <td>
                    ul. Jaracza, ul. Czerska
                    57-111, ul. Forteczna, ul.
                    Owcza, ul. Feniksa, ul.
                    Jutrzenki 1 i 1a, ul. Judyma
                  </td>
                </tr>
                <tr>
                  <td>23.01, 16:00</td>
                  <td>
                    ul. Czerska 2-36, ul. Czerska
                    1-55
                  </td>
                </tr>
                <tr>
                  <td>24.01, 16:00</td>
                  <td>
                    ul. Czahary od 2-34 i 3-27,
                    ul. Sielska, ul. Czahary(od
                    numerów końcowych), ul.
                    Sobótki, ul. Parkowa
                  </td>
                </tr>
                <tr>
                  <td>25.01, 16:00</td>
                  <td>
                    ul. Literacka, ul. Olchowa
                  </td>
                </tr>
                <tr>
                  <td>26.01, 16:00</td>
                  <td>
                    ul. Hodowlana, ul. Rzepichy,
                    ul. Ludowa
                  </td>
                </tr>
                <tr>
                  <td>27.01, 11:00</td>
                  <td>
                    ul. Hippiczna - nr
                    nieparzyste, ul. Hippiczna –
                    nr parzyste
                  </td>
                </tr>
                <tr>
                  <td>29.01, 16:00</td>
                  <td>
                    ul. Barwinkowa, ul. Epokowa,
                    ul. Barwinkowa 1-29 i 26-28c
                  </td>
                </tr>
                <tr>
                  <td>30.01, 16:00</td>
                  <td>
                    ul. Goplany, ul. Herbowa, ul.
                    Hebanowa, ul. Dworska, ul.
                    Arlekina, ul. Alpejska, ul.
                    Bazaltowa
                  </td>
                </tr>
                <tr>
                  <td>
                    &nbsp;31.01, 16:00&nbsp;
                  </td>
                  <td>
                    ul. Niedzielna, ul. Miła, ul.
                    Lechicka, ul. Młoda, ul.
                    Turkusowa, ul. Piesza, ul.
                    Mokra
                  </td>
                </tr>
                <tr>
                  <td>1.02, 16:00</td>
                  <td>
                    ul. Temidy, ul. Jantarowej 8 i
                    10, ul. Lutników, ul.
                    Ogniskowa, ul. Trawiasta, ul.
                    Nizinna
                  </td>
                </tr>
                <tr>
                  <td>3.02, 11:00</td>
                  <td>
                    ul. Żeńców, ul. Kadetów, ul.
                    Jantarowa, ul. Uroczysko, ul.
                    Barycka
                  </td>
                </tr>
                <tr>
                  <td>5.02, 16:00</td>
                  <td>
                    ul. Popiela, ul. Moczary, ul.
                    Reduty, ul. Jutrzenki
                  </td>
                </tr>
              </tbody>
            </table> */}
          {/* </article> */}
          <img
            className='basic-wire'
            src={basicWire}
            alt='fala'
          />
        </div>
        <Footer />
      </section>
    </div>
  );
};

export default Home;
