import React from 'react';
import Navbar from '../../components/Navbar';
import Sidebar from '../../components/Sidebar';
import Footer from '../../components/Footer';
import ScrollButton from '../../utilities/ScrollButton';

import basicWire from '../../images/basic-wire.svg';
import brownBasicWire from '../../images/brown-basic-wire.svg';
import redBasicWire from '../../images/red-basic-wire.svg';
import chaliceWithBird from '../../images/chalice-with-bird.svg';
import handsWithFire from '../../images/hands-with-fire.svg';
import book from '../../images/book.svg';

import './Sakramenty.scss';

const Liturgia = () => {
  return (
    <div className='animation-wrapper'>
      <main className='default-container animation-section'>
        <Navbar />
        <Sidebar />
        <article
          className='default-content-wrapper'
          id='chrzest-content-wrapper'
        >
          <h1 className='subpage-title-left'>
            Chrzest
          </h1>
          <div className='chrzest-content'>
            <strong>
              Sakrament chrztu świętego sprawowany
              jest w I sobotę miesiąca podczas
              Mszy św. o godz. 18:00 i III
              niedzielę miesiąca podczas Mszy św.
              o godz. 12:00 oraz w Uroczystość
              Narodzenia Pańskiego i Wielkanocy
              podczas Mszy św. o godz. 12:00. W
              innych terminach poza Mszą świętą.
            </strong>
            <br />
            <br />W kancelarii parafialnej Rodzice
            powinni przedłożyć akt urodzenia
            dziecka oraz podać dane o rodzicach
            chrzestnych (imię i nazwisko, wiek i
            miejsce zamieszkania). Katecheza przed
            chrztem, z udziałem rodziców i
            chrzestnych - w dniu chrztu 30 minut
            przed Mszą św. Jeśli rodzice chrzestni
            są spoza naszej parafii, winni
            przedstawić z parafii zamieszkania
            zaświadczenie, że mogą być dopuszczeni
            do godności rodziców chrzestnych.
          </div>
        </article>
        <img
          className='chrzest-chalice-with-bird'
          src={chaliceWithBird}
          alt='krzyze'
        />
        <img
          className='basic-wire brown'
          src={brownBasicWire}
          alt='fala'
        />
        <img
          className='basic-wire red'
          src={redBasicWire}
          alt='fala'
        />
        <ScrollButton />
      </main>
      <section className='default-container animation-section'>
        <Navbar />
        <Sidebar />
        <article
          className='default-content-wrapper'
          id='eucharystia-content-wrapper'
        >
          <h1 className='subpage-title-left'>
            Eucharystia
          </h1>
          <div className='eucharystia-content'>
            <strong>
              Uprzywilejowanym miejscem
              przygotowania do I Komunii świętej
              jest parafia zamieszkania dziecka, a
              nie parafia, na terenie której
              znajduje się szkoła. Spotkania
              formacyjne prowadzone w parafii
              stanowią dopełnienie lekcji religii,
              zwłaszcza w funkcji wtajemniczenia i
              wychowania. Udział w nauczaniu
              religii w szkole nie może zastąpić
              parafialnego przygotowania do
              sakramentu.
            </strong>
            <br />
            <br />
            Opiekun : ks. wikariusz. We wrześniu
            rodzice kandydatów odbierają
            deklarację zgłaszającą swoje dziecko
            do bierzmowania i materiały formacyjne
            na dany rok. Formacja przed
            przystąpieniem do I komunii św.
            rozpoczyna się na początku klasy III
            szkoły podstawowej i trwa około roku.
            Dzieci przystępują do I Komunii św. w
            III niedzielę maja na Mszy św. o godz.
            10:00. Spotkania formacyjne dla
            kandydatów odbywają się raz w miesiącu
            a ich terminy są zamieszczone w
            materiałach formacyjnych, które
            rodzice odbierają we wrześniu. Dzieci
            wraz z Rodzicami uczestniczą
            systematycznie w niedzielnej Mszy św.,
            po której odbierają między innymi
            „Owcę” (materiały liturgiczne) a w
            kolejną niedzielę wypełnione oddają w
            kościele.
          </div>
        </article>
        <img
          className='eucharystia-book'
          src={book}
          alt='krzyze'
        />
        <img
          className='basic-wire'
          src={basicWire}
          alt='fala'
        />
        <ScrollButton />
      </section>
      <section className='default-container animation-section'>
        <Navbar />
        <Sidebar />
        <article
          className='default-content-wrapper'
          id='bierzmowanie-content-wrapper'
        >
          <h1 className='subpage-title-left'>
            Bierzmowanie
          </h1>
          <div className='bierzmowanie-content'>
            <strong>
              Uprzywilejowanym miejscem
              przygotowania do bierzmowania jest
              parafia zamieszkania kandydata, a
              nie parafia, na terenie której
              znajduje się szkoła. Spotkania
              formacyjne prowadzone w parafii
              stanowią dopełnienie lekcji religii,
              zwłaszcza w funkcji wtajemniczenia i
              wychowania. Udział w nauczaniu
              religii w szkole nie może zastąpić
              parafialnego przygotowania do
              sakramentu.
            </strong>
            <br />
            <br />
            Opiekun jest ks. proboszcz. We
            wrześniu rodzice kandydatów odbierają
            deklarację zgłaszającą swoje dziecko
            do bierzmowania i materiały formacyjne
            na dany rok. Formacja przed
            bierzmowaniem rozpoczyna się w klasie
            I szkoły ponadpodstawowej i trwa około
            roku. Spotkania formacyjne dla
            kandydatów odbywają się raz w miesiącu
            a ich terminy są zamieszczone w
            materiałach formacyjnych, które
            rodzice odbierają we wrześniu.
            Młodzież uczestniczy systematycznie w
            niedzielnej Mszy św., po której
            odbiera „Ziarno” (materiały
            liturgiczne) a w kolejną niedzielę
            wypełnione oddaje w kościele.
          </div>
        </article>
        <img
          className='bierzmowanie-hands-with-fire'
          src={handsWithFire}
          alt='krzyze'
        />
        <img
          className='basic-wire brown'
          src={brownBasicWire}
          alt='fala'
        />
        <img
          className='basic-wire red'
          src={redBasicWire}
          alt='fala'
        />
        <ScrollButton />
      </section>
      <div className='animation-section'>
        <section className='default-container '>
          <Navbar />
          <Sidebar />
          <article className='default-content-wrapper-without-img'>
            <h1 className='subpage-title-center'>
              Małżeństwo
            </h1>
            <div className='malzenstwo-content'>
              <strong>
                Przygotowanie do Sakramentu
                Małżeństwa:
              </strong>
              <br />
              <b>1.</b> Rezerwację terminu ślubu
              można uczynić nawet rok przed samym
              zawarciem sakramentu małżeństwa.
              <br />
              <b>2.</b> Na około 3 miesiące przed
              zawarciem sakramentu małżeństwa
              należy umówić się z kapłanem na
              spisanie protokołu przedślubnego. Na
              spisanie tego protokołu trzeba
              przynieść następujące dokumenty:
              <br />
              &bull; dowody osobiste narzeczonego
              i narzeczonej, pełny odpis aktu
              chrztu nie starszy niż 6 miesiące,
              <br />
              &bull; świadectwo bierzmowania,
              jeżeli brak takiej adnotacji o nim
              na świadectwie chrztu,
              <br />
              &bull; zaświadczenie z Urzędu Stanu
              Cywilnego (do ślubu konkordatowego)
              ważne 6 miesięcy, akt ślubu jeżeli
              wcześniej został zawarty związek
              cywilny, <br />
              &bull; zaświadczenie o ukończeniu
              katechezy przedmałżeńskiej.
              <br />
              <b>3.</b> Jeżeli narzeczony lub
              narzeczona mieszkają poza terenem
              naszej parafii - wtedy przy
              spisywaniu protokółu przedślubnego
              otrzymują „prośbę o wygłoszenie
              zapowiedzi”, którą zanoszą do
              kancelarii parafialnej parafii
              miejsca swojego zamieszkania.
              Zgodnie z Kodeksem Prawa
              Kanonicznego takie zapowiedzi muszą
              zostać wygłoszone w parafii
              zamieszkania przez dwie kolejne
              niedziele.
              <br />
              <b>4.</b> Po spisaniu protokołu
              przedślubnego narzeczeni muszą odbyć
              trzy spotkania w poradnictwie
              rodzinnym (mogą to uczynić także
              wcześniej) o ile nie odnotowano tego
              na zaświadczaniu o ukończeniu
              katechezy parafialnej.
              <br />
              <br />
              <strong>
                Poradnictwo rodzinne:
              </strong>
              <br />
              Celem poradnictwa jest: pomoc w
              organizowaniu kursów
              przedmałżeńskich, prowadzenie
              spotkań przedślubnych, a także pomoc
              małżeństwoim, które znalazły się w
              sytuacji kryzysowej. Kontakt w celu
              ustalenia spotkania:
              <br />
              pp. Dariusz i Jolanta Lis
              <br />
              tel. 609410170 lub 609130170.
              <br />
            </div>
          </article>
          <img
            className='basic-wire'
            src={basicWire}
            alt='fala'
          />
        </section>
        <Footer />
      </div>
    </div>
  );
};

export default Liturgia;
