import React from 'react';
import Navbar from '../../components/Navbar';
import Sidebar from '../../components/Sidebar';
import Footer from '../../components/Footer';

import basicWire from '../../images/basic-wire.svg';

import './Kontakt.scss';

const Kontakt = () => {
  return (
    <div className='animation-wrapper'>
      <div className='animation-section'>
        <main className='default-container'>
          <Navbar />
          <Sidebar />
          <article className='default-content-wrapper-without-img'>
            <h1 className='subpage-title-left'>
              Kontakt
            </h1>
            <div className='flexbox-wrapper'>
              <div
                className='kontakt-flexbox-element'
                id='kontakt-flexbox-info'
              >
                <p>
                  <strong>
                    Kancelaria Parafialna
                  </strong>
                  <br />
                  Wtorek: 16:00 - 17:00
                  <br />
                  Czwartek: 16:00 - 17:00
                </p>

                <p>
                  <strong>Kontakt</strong>
                  <br />
                  kontakt@parafiabb.pl
                  <br />
                  86-005 Białe Błota, ul. Czerska
                  40
                  <br />
                  <a
                    href='https://www.facebook.com/parafiabb'
                    target='_blank'
                    rel='noreferrer'
                  >
                    Znajdziesz nas na{' '}
                    <strong>Facebooku</strong>!
                  </a>
                  <br />
                  Konto w Banku Spółdzielczym w
                  Bydgoszczy:{' '}
                  <strong>
                    59 8142 1020 0000 3942 2000
                    0001
                  </strong>
                </p>
                <p>
                  <strong>
                    W sprawach pilnych
                  </strong>{' '}
                  codziennie bezpośrednio po Mszy
                  św. o godz. 8.00 i 18.00. W
                  sprawach losowych, szczególnie
                  dotyczących odwiedzin chorego i
                  pogrzebu o każdej porze albo pod
                  numerem telefonu:{' '}
                  <b>881 926 303</b>.
                </p>
              </div>
              <iframe
                src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2395.8504367265386!2d17.91165431597529!3d53.09492100121451!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470312bfb36262d3%3A0x2b00365db9be1612!2zS2_Fm2Npw7PFgiBDaHJ5c3R1c2EgRG9icmVnbyBQYXN0ZXJ6YSB3IEJpYcWCeWNoIELFgm90YWNo!5e0!3m2!1spl!2spl!4v1621887232272!5m2!1spl!2spl'
                id='kontakt-flexbox-map'
                className='kontakt-flexbox-element'
                allowfullscreen=''
                loading='lazy'
                title='Lokalizacja na Google'
              ></iframe>
            </div>
          </article>
          <img
            className='basic-wire'
            src={basicWire}
            alt='fala'
          />
        </main>
        <Footer />
      </div>
    </div>
  );
};

export default Kontakt;
