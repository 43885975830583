import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import AppProvider from './AppContext';
import Views from './Views';

const App = () => (
  <Router>
    <AppProvider>
      <Views />
    </AppProvider>
  </Router>
);

export default App;
