import React, { useContext } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { GoThreeBars } from 'react-icons/go';

import { AppContext } from '../AppContext';

import logoDark from '../../images/logo-dark.svg';

import './Navbar.scss';

const Navbar = () => {
  const { toggleSidebar } =
    useContext(AppContext);

  return (
    <nav className='nav-main'>
      <div className='nav-main-container'>
        <Link className='nav-main-logo' to='/'>
          <img src={logoDark} alt='parafiabb' />
        </Link>
        <div
          className='nav-main-mobile-icon'
          onClick={toggleSidebar}
        >
          <GoThreeBars />
        </div>
        <ul className='nav-main-menu'>
          <li className='nav-main-menu-item'>
            <NavLink to='/aktualnosci'>
              Aktualności
            </NavLink>
          </li>
          <li className='nav-main-menu-item'>
            <NavLink to='/intencje'>
              Intencje
            </NavLink>
          </li>
          <li className='nav-main-menu-item'>
            <NavLink to='/liturgia'>
              Liturgia
            </NavLink>
          </li>
          <li className='nav-main-menu-item'>
            <NavLink to='/sakramenty'>
              Sakramenty
            </NavLink>
          </li>
          <li className='nav-main-menu-item'>
            <NavLink to='/wspolnoty'>
              Wspólnoty
            </NavLink>
          </li>
          <li className='nav-main-menu-item'>
            <NavLink to='/galeria'>
              Galeria
            </NavLink>
          </li>
        </ul>
        <div className='nav-main-button'>
          <NavLink to='/kontakt'>Kontakt</NavLink>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
