import React from 'react';
import Navbar from '../../components/Navbar';
import Sidebar from '../../components/Sidebar';
import Footer from '../../components/Footer';

import brownBasicWire from '../../images/brown-basic-wire.svg';
import redBasicWire from '../../images/red-basic-wire.svg';
import hands from '../../images/3hands.svg';

import './Wspolnoty.scss';

const Wspolnoty = () => {
  return (
    <div className='animation-wrapper'>
      <div className='animation-section'>
        <main className='default-container'>
          <Navbar />
          <Sidebar />
          <article
            className='default-content-wrapper'
            id='wspolnoty-content-wrapper'
          >
            <h1 className='subpage-title-left'>
              Wspólnoty
            </h1>
            <p className='wspolnoty-content'>
              <strong>Rada ekonomiczna:</strong>{' '}
              według potrzeb duszpasterskich
              ustalanych na bieżąco (opiekun ks.
              proboszcz).
              <br />
              <strong>
                Rada duszpasterska:
              </strong>{' '}
              według potrzeb duszpasterskich
              ustalanych na bieżąco (opiekun ks.
              proboszcz).
              <br />
              <strong>Katecheci:</strong> według
              potrzeb duszpasterskich ustalanych
              na bieżąco (opiekun ks. proboszcz).
              <br />
              <strong>
                Koło synodalne:
              </strong>{' '}
              według potrzeb duszpasterskich
              ustalanych na bieżąco (opiekun ks.
              proboszcz).
              <br />
              <strong>
                Służba liturgiczna szafarzy
                nadzwyczajnych komunii świętej:
              </strong>{' '}
              według potrzeb duszpasterskich
              ustalanych na bieżąco (opiekun ks.
              proboszcz).
              <br />
              <strong>
                Służba liturgiczna lektorów:
              </strong>{' '}
              według potrzeb duszpasterskich
              ustalanych na bieżąco (opiekun ks.
              wikariusz).
              <br />
              <strong>
                Służba liturgiczna ministrantów:
              </strong>{' '}
              według potrzeb duszpasterskich
              ustalanych na bieżąco (opiekun ks.
              wikariusz).
              <br />
              <strong>
                Poradnictwo rodzinne dla
                narzeczonych i małżeństw
                przeżywających kryzys:
              </strong>{' '}
              według potrzeb duszpasterskich
              ustalanych na bieżąco (opiekun ks.
              proboszcz).
              <br />
              <strong>Żywy Różaniec:</strong> I
              czwartek miesiąca po Mszy św. o
              godz. 18.30 (opiekun: ks.
              proboszcz).
              <br />
              <strong>
                Stowarzyszenie Wspierania Powołań:
              </strong>{' '}
              I czwartek miesiąca po Mszy św. o
              godz. 18.30 (opiekun: ks.
              proboszcz).
              <br />
              <strong>
                Apostolstwo Dobrej Śmierci:
              </strong>{' '}
              I czwartek miesiąca po Mszy św. o
              godz. 18.30 (opiekun: ks.
              proboszcz).
              <br />
              <strong>
                Koło Przyjaciół Radia Maryja:
              </strong>{' '}
              I czwartek miesiąca po Mszy św. o
              godz. 18.30 (opiekun: ks.
              proboszcz).
              <br />
              <strong>Koło Caritas:</strong> I
              czwartek miesiąca po Mszy św. o
              godz. 18.30 (opiekun: ks.
              proboszcz).
              <br />
              <strong>
                Domowy Kościół:
              </strong>{' '}
              spotkanie raz w miesiącu z rodzinami
              kręgu w ich domach (opiekun: ks.
              wikariusz).
              <br />
              <strong>
                Grupa Wsparcia AA dla osób z
                problemami alkoholowymi:
              </strong>{' '}
              wtorki od godz. 17.00-19.00
              (opiekun: ks. proboszcz).
              <br />
              <strong>
                Schola dzieci i młodzieży:
              </strong>{' '}
              próby w każdą niedzielę od godz.
              11.00 i oprawa Mszy św. o godz.
              12.00 (opiekun: ks. proboszcz).
              <br />
              <strong>
                Odnowa w Duchu Świętym:
              </strong>{' '}
              czwartki, Msza św. o godz. 18.00 i
              spotkanie modlitewne do godz. 20.00
              (opiekun: ks. wikariusz).
              <br />
              <strong>
                Grupa modlitewna „W duchu Maryi”:
              </strong>{' '}
              I i III piątek miesiąca, Msza św. o
              godz. 18.00 i spotkanie modlitewne
              do godz. 21.00 (opiekun: ks.
              wikariusz).
              <br />
              <strong>
                Pielgrzymkowa Grupa Rowerowa:
              </strong>{' '}
              według potrzeb duszpasterskich
              ustalanych na bieżąco (opiekun ks.
              proboszcz).
              <br />
              <strong>
                Animacja misyjna dzieci:
              </strong>{' '}
              II i IV piątek od godz. 18.30-20.00.
              <br />
              <strong>
                Animacja misyjna młodzieży:
              </strong>{' '}
              I i III piątek od godz. 18.30-20.00.
              <br />
              <strong>
                Animacja misyjna dorosłych:
              </strong>{' '}
              I środa od godz. 18.30-20.00.
            </p>
          </article>
          <img
            className='wspolnoty-image'
            src={hands}
            alt='rece'
          />
          <img
            className='basic-wire brown'
            src={brownBasicWire}
            alt='fala'
          />
          <img
            className='basic-wire red'
            src={redBasicWire}
            alt='fala'
          />
        </main>
        <Footer />
      </div>
    </div>
  );
};

export default Wspolnoty;
