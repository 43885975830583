import React from 'react';
import {
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';

import Home from '../pages/Home';
import Intencje from '../pages/Intencje';
import Liturgia from '../pages/Liturgia';
import Sakramenty from '../pages/Sakramenty';
import Wspolnoty from '../pages/Wspolnoty';
import Galeria from '../pages/Galeria';
import Kontakt from '../pages/Kontakt';
import Duszpasterze from '../pages/Duszpasterze';
import Parafia from '../pages/Parafia';
import ScrollToTop from '../utilities/ScrollToTop';

const Views = () => {
  return (
    <>
      <ScrollToTop>
        <Routes>
          <Route path='*' element={<Home />} />
          {/* <Route
            path='/duszpasterze/wlodzimierz-reformat'
            element={
              <h1>wlodzimierz reformat</h1>
            }
          /> */}
          <Route
            exact
            path='/duszpasterze'
            element={<Duszpasterze />}
          />
          <Route
            path='/parafia'
            element={<Parafia />}
          />
          <Route
            path='/kontakt'
            element={<Kontakt />}
          />
          <Route
            path='/galeria'
            element={<Galeria />}
          />
          <Route
            path='/wspolnoty'
            element={<Wspolnoty />}
          />
          <Route
            path='/sakramenty'
            element={<Sakramenty />}
          />
          <Route
            path='/liturgia'
            element={<Liturgia />}
          />
          <Route
            path='/intencje-i-ogloszenia'
            element={
              <Navigate replace to='/intencje' />
            }
          />
          <Route
            path='/intencje'
            element={<Intencje />}
          />
          <Route
            path='/aktualnosci'
            element={<Home />}
          />

          <Route index element={<Home />} />
        </Routes>
      </ScrollToTop>
    </>
  );
};

export default Views;
